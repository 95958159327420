import clsx from "clsx";
import firebase from "firebase/compat/app";
import moment from "moment";
import { FC, Fragment, PropsWithChildren, useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";

type ValueCellProp = {
  value: number;
  data: any;
};

const MarketplaceValueCell: FC<ValueCellProp> = ({ value, data }) => {
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const { t } = useTranslation();
  let progress = Math.min(Math.max(value, 0), 3);
  const [show, setShow] = useState(false);

  let connected_erp = false;
  let connected_chat = false;
  let expired_accesstoken_erp = false;
  let expired_accesstoken_chat = false;
  if (data.type === "shopee") {
    const shopee_exp = data[`${data.type}_expiredIn`];
    const shopee_expMilliseconds =
      shopee_exp?.seconds * 1000 + shopee_exp?.nanoseconds / 1000000;
    const shopee_expDate = new Date(shopee_expMilliseconds);
    const currentDate = new Date();
    connected_erp = true;
    connected_chat = false;
    if (shopee_expDate < currentDate) {
      expired_accesstoken_erp = true;
      expired_accesstoken_chat = true;
    }
  }
  if (data.type === "tokopedia") {
    const tokopedia_exp = data[`${data.type}_accessTokenExpired`];
    const tokopedia_expMilliseconds =
      tokopedia_exp?.seconds * 1000 + tokopedia_exp?.nanoseconds / 1000000;
    const tokopedia_expDate = new Date(tokopedia_expMilliseconds);
    const currentDate = new Date();
    connected_erp = true;
    connected_chat = true;
    if (tokopedia_expDate < currentDate) {
      expired_accesstoken_erp = true;
      expired_accesstoken_chat = true;
    }
  }
  if (data.type === "lazada") {
    const lazerp_exp = data?.credentials?.erp?.accessTokenExpired;
    const lazerp_expMilliseconds =
      lazerp_exp?.seconds * 1000 + lazerp_exp?.nanoseconds / 1000000;
    const lazerp_expDate = new Date(lazerp_expMilliseconds);
    const lazchat_exp = data?.credentials?.chat?.accessTokenExpired;
    const lazchat_expMilliseconds =
      lazchat_exp?.seconds * 1000 + lazchat_exp?.nanoseconds / 1000000;
    const lazchat_expDate = new Date(lazchat_expMilliseconds);
    const currentDate = new Date();
    if (!!lazchat_expDate) {
      connected_chat = true;
    }
    if (!!lazerp_expDate) {
      connected_erp = true;
    }
    if (lazerp_expDate < currentDate) {
      expired_accesstoken_erp = true;
    }
    if (lazchat_expDate < currentDate) {
      expired_accesstoken_chat = true;
    }
  }
  if (data.type === "tiktok") {
    const tiktok_exp = data?.credentials?.erp?.accessTokenExpired;
    const tiktok_expMilliseconds =
      tiktok_exp?.seconds * 1000 + tiktok_exp?.nanoseconds / 1000000;
    const tiktok_expDate = new Date(tiktok_expMilliseconds);
    const tiktok_erp_con = data?.credentials?.erp?.connectedAt;
    const tiktok_chat_con = data?.credentials?.chat?.connectedAt;
    const tiktok_chat_exp = data?.credentials?.chat?.accessTokenExpired;
    const tiktok_chat_expMilliseconds =
      tiktok_chat_exp?.seconds * 1000 + tiktok_chat_exp?.nanoseconds / 1000000;
    const tiktok_chat_expDate = new Date(tiktok_chat_expMilliseconds);
    const currentDate = new Date();
    if (tiktok_erp_con) {
      connected_erp = true;
    }
    if (tiktok_chat_con) {
      connected_chat = true;
    }
    if (tiktok_expDate < currentDate) {
      expired_accesstoken_erp = true;
    }
    if (tiktok_chat_expDate < currentDate) {
      expired_accesstoken_chat = true;
    }
  }
  if (expired_accesstoken_chat === true || expired_accesstoken_erp === true) {
    progress = 3;
  }

  return (
    <>
      {!isTabletOrMobile ? (
        <OverlayTrigger
          key={data.name}
          placement="top"
          overlay={
            <Tooltip
              id={`tooltip-${data.name}`}
              style={{ backgroundColor: "#f0f8ff", color: "black" }}
            >
              {t("Marketplace.Status.Expired")}
            </Tooltip>
          }
          show={show}
        >
          <div>
            <div
              // style={{ width: isTabletOrMobile ? "70px" : "100px" }}
              className="progress position-relative"
            >
              <div
                className={`progress-bar bg-${
                  expired_accesstoken_chat === true ||
                  expired_accesstoken_erp === true
                    ? "danger"
                    : Math.round((progress / 3) * 100) > 70
                    ? "success"
                    : Math.round((progress / 3) * 100) > 40
                    ? "warning"
                    : "danger"
                }`}
                role="progressbar"
                style={{ width: `${(progress / 3) * 100}%` }}
                aria-valuenow={progress}
                aria-valuemin={0}
                aria-valuemax={3}
              ></div>
              {!(
                expired_accesstoken_chat === true ||
                expired_accesstoken_erp === true
              ) && (
                <div className="w-100 d-flex align-items-center justify-content-center position-absolute">
                  <p className="p-0 m-0">{Math.round((progress / 3) * 100)}%</p>
                </div>
              )}
            </div>
            {value !== 0 && (
              <p
                className="mt-2 m-0 p-0 text-muted fs-7 cursor-pointer"
                onMouseOver={() => {
                  if (
                    expired_accesstoken_chat === true ||
                    expired_accesstoken_erp === true
                  ) {
                    setShow(!show);
                  }
                }}
                onMouseLeave={() => setShow(false)}
              >
                {expired_accesstoken_chat === true ||
                expired_accesstoken_erp === true
                  ? t("Marketplace.Progress.Problem")
                  : value === 3
                  ? t("Marketplace.Progress.Completed")
                  : t("Marketplace.Progress.On")}
              </p>
            )}
          </div>
        </OverlayTrigger>
      ) : (
        <OverlayTrigger
          key={data.name}
          placement="top"
          overlay={
            <Tooltip
              id={`tooltip-${data.name}`}
              style={{ backgroundColor: "#f0f8ff", color: "black" }}
            >
              {t("Marketplace.Status.Expired")}
            </Tooltip>
          }
          show={show}
        >
          <div className="pb-5 pt-2">
            {value !== 0 && (
              <p
                className="mt-2 m-0 p-0 text-muted fs-4 cursor-pointer"
                onMouseOver={() => {
                  if (
                    expired_accesstoken_chat === true ||
                    expired_accesstoken_erp === true
                  ) {
                    setShow(!show);
                  }
                }}
                onMouseLeave={() => setShow(false)}
              >
                {expired_accesstoken_chat === true ||
                expired_accesstoken_erp === true
                  ? "Something Went Wrong"
                  : value === 3
                  ? t("Marketplace.Progress.Completed")
                  : t("Marketplace.Progress.On")}
              </p>
            )}
            <div
              // style={{ width: isTabletOrMobile ? "70px" : "100px" }}
              className="progress position-relative"
            >
              <div
                className={`progress-bar bg-${
                  expired_accesstoken_chat === true ||
                  expired_accesstoken_erp === true
                    ? "danger"
                    : Math.round((progress / 3) * 100) > 70
                    ? "success"
                    : Math.round((progress / 3) * 100) > 40
                    ? "warning"
                    : "danger"
                }`}
                role="progressbar"
                style={{ width: `${(progress / 3) * 100}%` }}
                aria-valuenow={progress}
                aria-valuemin={0}
                aria-valuemax={3}
              ></div>
              {!(
                expired_accesstoken_chat === true ||
                expired_accesstoken_erp === true
              ) && (
                <div className="w-100 d-flex align-items-center justify-content-center position-absolute">
                  <p className="p-0 m-0">{Math.round((progress / 3) * 100)}%</p>
                </div>
              )}
            </div>
          </div>
        </OverlayTrigger>
      )}
    </>
  );
};

export default MarketplaceValueCell;
