import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { scanBarcode } from "src/api/server/prepacks";
import ShippingMarkModal from "./ShippingMarkModal";
import axios from "axios";
import db from "src/db";
import * as lc from "../../../../../app/modules/localstorage/index";
import { KTSVG } from "src/resources/helpers";

/**
 * ScanLocatorModalProps Interface
 */
type ScanLocatorModalProps = {
  onClose: () => void;
  onSubmit?: (data: { locator: string; sku: string }) => void;
};

/**
 * A simple loading spinner component that displays a small, primary-colored spinner.
 * The spinner is wrapped in a <div> with a "spinner-border" class and an aria role of "status".
 * The spinner itself is given a "spinner-border-sm" class to make it smaller.
 * The loading text is hidden from screen readers with the "visually-hidden" class.
 */
const LoadingSpinner = () => (
  <div className="spinner-border spinner-border-sm text-primary" role="status">
    <span className="visually-hidden">Loading...</span>
  </div>
);

/**
 * ScanLocatorModal is a modal component that allows the user to scan a locator and input a SKU.
 * It will validate the locator and SKU and submit the form if both are valid.
 * It also handles errors and displays them to the user.
 *
 * @param {function} onClose - A function to call when the modal is closed.
 * @param {function} onSubmit - A function to call when the form is submitted.
 * @returns {JSX.Element} - The modal component.
 */
const ScanLocatorModal: React.FC<ScanLocatorModalProps> = ({
  onClose,
  onSubmit,
}) => {
  // Hook for translation
  const { t } = useTranslation();

  // References for input elements
  const locatorInputRef = useRef<HTMLInputElement>(null);
  const skuInputRef = useRef<HTMLInputElement>(null);

  // State for managing locator and SKU values
  const [locator, setLocator] = useState<string>("");
  const [locatorId, setLocatorId] = useState<string>("");
  const [sku, setSku] = useState<string>("");

  // State for enabling/disabling input fields
  const [isLocatorDisabled, setIsLocatorDisabled] = useState<boolean>(false);
  const [isSkuDisabled, setIsSkuDisabled] = useState<boolean>(false);

  // State for storing a list of SKUs and their quantities
  const [skuAndQtyList, setSkuAndQtyList] = useState<
    Array<{ sku: string; onHandQty: number }>
  >([]);

  // Loading states for asynchronous operations
  const [isLocatorLoading, setIsLocatorLoading] = useState<boolean>(false);
  const [isSkuLoading, setIsSkuLoading] = useState<boolean>(false);

  // State for handling errors in locator and SKU inputs
  const [error, setError] = useState<{
    locator?: string;
    sku?: string;
  }>({});

  // State for handling success messages
  const [success, setSuccess] = useState<string>("");

  // State for managing the visibility of the Shipping Mark Modal
  const [isShippingMarkModalOpen, setIsShippingMarkModalOpen] =
    useState<boolean>(false);

  // State for storing the printer URL
  const [printerUrl, setPrinterUrl] = useState<string | null>(null);

  // Check if printerUrl is already stored in localStorage
  useEffect(() => {
    const storedUrl = localStorage.getItem("printerUrl");

    if (storedUrl) {
      // If it exists, use the value from localStorage directly
      setPrinterUrl(storedUrl);
    } else {
      /**
       * Fetches the printer URL from Firestore and stores it in local storage.
       * This function is called only once when the component is mounted, and it sets the
       * printerUrl state to the fetched value.
       */
      const fetchPrinterUrl = async () => {
        try {
          // Fetch the printer URL from Firestore
          const doc = await db
            .collection("clients")
            .doc(lc.getClientId())
            .collection("api")
            .doc("push")
            .get();

          // Get the printer URL from the document data
          const url = doc.data()?.printer?.url;

          if (url) {
            // Store the URL in localStorage for future use
            localStorage.setItem("printerUrl", url);
            // Set the printerUrl state to the fetched value
            setPrinterUrl(url);
          }
        } catch (error) {
          console.error("Error fetching printer URL:", error);
        }
      };

      fetchPrinterUrl();
    }
  }, []);

  // Auto-focus locator input on component mount
  useEffect(() => {
    // Focus on locator input when the component is mounted
    locatorInputRef.current?.focus();
  }, []);

  /**
   * Handles changes to the locator input field.
   *
   * Updates the state with the new locator value from the input.
   * Clears any previous locator errors, so that the error state is reset
   * when the user types a new value.
   *
   * @param {React.ChangeEvent<HTMLInputElement>} e - The change event from the locator input.
   */
  const handleLocatorChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ): void => {
    const value = e.target.value;
    setLocator(value);
    // Clear previous locator errors
    setError((prev) => ({ ...prev, locator: undefined }));
  };

  /**
   * Handles the blur event for the locator input field.
   * Validates the locator and updates the error state if invalid.
   * If the locator is valid, it locks the locator input and focuses on the SKU input.
   * Sets loading state during the validation process.
   * Handles errors such as locator not found or insufficient stock, and updates the error state accordingly.
   * Clears the locator input and focuses it if validation fails.
   * Logs warnings in case of any unexpected errors during the process.
   */
  const handleLocatorBlur = async () => {
    // If the locator is empty, display an error message
    if (!locator.trim()) {
      setError((prev) => ({
        ...prev,
        locator: t("Value.Pack.Enter.Locator"),
      }));
      return;
    }

    // Set loading state while validating the locator
    setIsLocatorLoading(true);
    setError({});

    try {
      // Validate the locator by calling the scanBarcode function
      const isValidLocator = await scanBarcode(1, locator);

      // Clear loading state after validation is complete
      setIsLocatorLoading(false);

      // Handle errors if any error response
      if (isValidLocator?.statusCode === 400) {
        // If the locator is not found, display an error message
        if (isValidLocator?.error?.code === "LOCATOR_NOT_FOUND") {
          setError((prev) => ({
            ...prev,
            locator:
              t("Value.Pack.Error.Locator") +
              locator +
              t("Value.Pack.Error.Locator.Detail"),
          }));
        }
        // If there is insufficient stock, display an error message
        else if (isValidLocator?.error?.code === "INSUFFICIENT_STOCK") {
          setError((prev) => ({
            ...prev,
            locator:
              t("Value.Pack.Error.Locator.Qty") +
              locator +
              t("Value.Pack.Error.Locator.Detail"),
          }));
        }
        // Clear the locator input and focus it if validation fails
        setLocator("");
        locatorInputRef.current?.focus();
        return;
      }

      // Log the locator data
      console.info("Locator :", isValidLocator);

      // Set the locator ID
      setLocatorId(isValidLocator?.data?.locatorId || "");

      // Save the SKU and onHandQty data to state
      if (isValidLocator?.data?.storageData) {
        const skuAndQtyData = isValidLocator.data.storageData.map(
          (item: any) => ({
            sku: item.sku,
            onHandQty: item.onHandQty,
          })
        );

        // Log the SKU and onHandQty list before setting the state
        console.log("SKU and Quantity List:", skuAndQtyData);

        // Set the SKU and onHandQty list to state
        setSkuAndQtyList(skuAndQtyData);
      }

      // Lock the locator input and focus the SKU input
      setIsLocatorDisabled(true);
      setTimeout(() => {
        skuInputRef.current?.focus();
      }, 0);
    } catch (error: any) {
      // Log warnings in case of any unexpected errors during the process
      console.warn(error);
      // Clear loading state on error
      setIsLocatorLoading(false);
    }
  };

  /**
   * Handler to enable editing of the locator field.
   * Resets the locator, SKU, and error states to allow for new input.
   * Unlocks the locator input field and focuses on it after a delay.
   */
  const handleEditLocator = () => {
    // Enable the locator input field for editing
    setIsLocatorDisabled(false);

    // Clear the SKU input field
    setSku("");

    // Reset any existing error messages
    setError({});

    // Clear the locator input field
    setLocator("");

    // Clear the stored locator ID
    setLocatorId("");

    // Focus on the locator input field after a delay to ensure it's re-rendered
    setTimeout(() => {
      locatorInputRef.current?.focus();
    }, 500);
  };

  /**
   * Handles the change event for the SKU input field.
   * Updates the SKU state with the entered value.
   * Clears any existing SKU error messages.
   *
   * @param e - The change event from the SKU input field.
   */
  const handleSkuInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const enteredSku = e.target.value;
    setSku(enteredSku);
    setError((prev) => ({ ...prev, sku: undefined }));
  };

  /**
   * Handles form submission.
   * Validates locator and SKU inputs before submission.
   * Submits request to server to scan SKU if inputs are valid.
   * Handles errors if any error response, and updates the error state accordingly.
   * If the submission is successful, it calls the onSubmit callback with the locator and SKU values.
   * Disables SKU input during submission and re-enables it after completion.
   */
  const handleSubmit = async () => {
    // Remove default error first
    setError({
      locator: "",
      sku: "",
    });
    setSuccess("");

    // Validate sku inputs before submission
    if (!sku) {
      setError((prev) => ({ ...prev, sku: t("Validation.SKU.Required") }));
      skuInputRef.current?.focus();
      return;
    }

    // Check if SKU exists in skuAndQtyList
    const skuExists = skuAndQtyList.some((item) => item.sku === sku);
    if (!skuExists) {
      setError((prev) => ({
        ...prev,
        sku: t("Value.Pack.SKU.NotFound", { sku, locator }),
      }));
      skuInputRef.current?.focus();
      return;
    }
    const qtySufficient = skuAndQtyList.some(
      (item) => item.sku === sku && item.onHandQty > 0
    );
    if (!qtySufficient) {
      setError((prev) => ({
        ...prev,
        locator:
          t("Value.Pack.Error.Locator.Qty") +
          locator +
          t("Value.Pack.Error.Locator.Detail"),
      }));
      skuInputRef.current?.focus();
      return;
    }
    console.log("sku exist:", skuExists);
    // Set loading state
    setIsSkuLoading(true);

    try {
      // Lock SKU input
      setIsSkuDisabled(true);
      const skuToScan = sku;
      // Clear loading state
      setIsSkuLoading(false);
      // Clear the SKU input
      setSku("");
      // Unlock SKU input
      setIsSkuDisabled(false);

      // Re Focus on SKU input
      skuInputRef.current?.focus();

      // Log the data to print
      console.info("[DATA TO PRINT] : ", {
        sku: skuToScan,
        locatorId: locatorId,
        locatorCode: locator,
      });

      // decrement the onHandQty of the scanned SKU
      // const updatedSkuAndQtyList = skuAndQtyList.map((item) => {
      //   if (item.sku === skuToScan) {
      //     return { ...item, onHandQty: item.onHandQty - 1 };
      //   }
      //   return item;
      // });
      // setSkuAndQtyList(updatedSkuAndQtyList);
      setSkuAndQtyList((prevList) =>
        prevList.map((item) =>
          item.sku === skuToScan
            ? { ...item, onHandQty: item.onHandQty - 1 }
            : item
        )
      );

      try {
        const printing = await axios.post(`${printerUrl}/api/print`, {
          sku: skuToScan,
          locatorId: locatorId,
          locatorCode: locator,
        });

        const responsePrinting = printing?.data;
        console.log("Response From Printer App:", responsePrinting);

        let itemCount = responsePrinting?.data?.remaining || 0;
        let skuScanned = responsePrinting?.data?.sku || skuToScan;

        console.log("Success Response:", {
          skuScanned,
          itemCount,
        });

        setSuccess(t("Value.Pack.SKU.Found", { skuScanned, itemCount }));

        onSubmit?.({ locator, sku: skuToScan });
      } catch (error: any) {
        console.error("Error from Printer API:", error);

        let errorMessage = "An unexpected error occurred";
        let errorCode = "";

        if (axios.isAxiosError(error)) {
          if (error.response) {
            // Server mengembalikan error (status 4xx atau 5xx)
            errorMessage =
              error.response.data?.message || "Server error occurred";
            errorCode = error.response.data?.code || "";
          } else if (error.request) {
            // Request terkirim tapi tidak ada response dari server
            errorMessage = "No response from server, please try again.";
          }
        }

        // Set error berdasarkan kode error yang diterima
        if (errorCode === "NO_ORDER_FOUND") {
          setError((prev) => ({
            ...prev,
            sku: t("Value.Pack.Order.NotFound"),
          }));
        } else if (errorCode === "ERROR_PRINTING_ORDER") {
          setError((prev) => ({
            ...prev,
            sku: t("Value.Pack.Error.Printing"),
          }));
        } else {
          setError((prev) => ({ ...prev, sku: errorMessage }));
        }

        console.log("Error Response:", {
          errorCode,
          errorMessage,
        });

        // Jika terjadi error, kembalikan qty yang telah dikurangi
        setSkuAndQtyList((prevList) =>
          prevList.map((item) =>
            item.sku === skuToScan
              ? { ...item, onHandQty: item.onHandQty + 1 }
              : item
          )
        );
      }

      // Request server to scan SKU
      // const printing = await axios.post(`${printerUrl}/api/print`, {
      //   sku: skuToScan,
      //   locatorId: locatorId,
      //   locatorCode: locator,
      // });

      // // Respons langsung dari axios sudah memiliki struktur data
      // const responsePrinting = printing.data; // Tidak perlu JSON.parse(JSON.stringify())

      // // Log the response data from printer
      // console.log("Response From Printer App : ", responsePrinting);

      // // Handle errors if any error response
      // if (printing.status !== 200) {
      //   // Ambil status dari `printing`, bukan `responsePrinting`
      //   console.log("Error Response : ", responsePrinting);

      //   // Set back the onHandQty of the scanned SKU
      //   const updatedSkuAndQtyList = skuAndQtyList.map((item) => {
      //     if (item.sku === skuToScan) {
      //       return { ...item, onHandQty: item.onHandQty + 1 };
      //     }
      //     return item;
      //   });
      //   setSkuAndQtyList(updatedSkuAndQtyList);

      //   //Handle errors if No Order Found
      //   if (responsePrinting.code === "NO_ORDER_FOUND") {
      //     // Tidak perlu `.data`
      //     setError((prev) => ({
      //       ...prev,
      //       sku: t("Value.Pack.Order.NotFound"),
      //     }));
      //   }
      //   //Handle errors if Printing Failed
      //   else if (responsePrinting.code === "ERROR_PRINTING_ORDER") {
      //     setError((prev) => ({
      //       ...prev,
      //       sku: t("Value.Pack.Error.Printing"),
      //     }));
      //   } else {
      //     setError((prev) => ({
      //       ...prev,
      //       sku: responsePrinting.message,
      //     }));
      //   }
      // } else {
      //   // Successful submission logic
      //   onSubmit?.({ locator, sku: skuToScan });

      //   // Set Success message
      //   let itemCount = responsePrinting.remaining || 0;
      //   let skuScanned = responsePrinting.sku || skuToScan;

      //   // Update success message
      //   setSuccess(t("Value.Pack.SKU.Found", { skuScanned, itemCount }));
      // }
    } catch (error: any) {
      console.warn(error);
      // Clear loading state on error
      setIsSkuLoading(false);
      setIsSkuDisabled(false);
      skuInputRef.current?.focus();
    }
  };

  return (
    <>
      <div
        className="modal-backdrop show"
        data-testid="scan-locator-modal"
      ></div>
      <div className="modal show d-block">
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            {/* Modal Header */}
            <div className="modal-header">
              <h5 className="modal-title">{t("Value.Pack.Scan.Locator")}</h5>
              <div
                className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={onClose}
              >
                <KTSVG
                  path="/media/icons/duotune/arrows/arr061.svg"
                  className="svg-icon svg-icon-2x"
                />
              </div>
            </div>

            {/* Modal Body */}
            <div className="modal-body">
              {/* Locator Input */}
              <div className="mb-3 text-start">
                <label htmlFor="locator-input" className="form-label">
                  {t("Value.Pack.Locator")}
                </label>
                <div className="input-group">
                  <input
                    id="locator-input"
                    ref={locatorInputRef}
                    type="text"
                    className={`form-control ${
                      error.locator ? "is-invalid" : ""
                    }`}
                    value={locator}
                    onChange={handleLocatorChange}
                    onBlur={handleLocatorBlur}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        handleLocatorBlur();
                      }
                    }}
                    disabled={isLocatorDisabled || isLocatorLoading}
                    placeholder={t("Value.Pack.Enter.Locator")}
                  />
                  {isLocatorLoading && (
                    <div className="input-group-text">
                      <LoadingSpinner />
                    </div>
                  )}
                  {isLocatorDisabled && (
                    <button
                      className="btn btn-transparent"
                      onClick={handleEditLocator}
                    >
                      <i className="bi bi-pencil ms-2 fs-3" />
                    </button>
                  )}
                  {error.locator && (
                    <div className="invalid-feedback">{error.locator}</div>
                  )}
                </div>
              </div>

              {/* SKU Input */}
              <div className="mb-3 text-start">
                <label htmlFor="sku-input" className="form-label">
                  {t("Value.Pack.SKU")}
                </label>
                <div className="input-group">
                  <input
                    id="sku-input"
                    ref={skuInputRef}
                    type="text"
                    className={`form-control ${error.sku ? "is-invalid" : ""}`}
                    value={sku}
                    onChange={handleSkuInput}
                    onBlur={handleSubmit}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();
                        handleSubmit();
                      }
                    }}
                    disabled={
                      !isLocatorDisabled || isSkuDisabled || isSkuLoading
                    }
                    placeholder={t("Value.Pack.Enter.SKU")}
                  />
                  {isSkuLoading && (
                    <div className="input-group-text">
                      <LoadingSpinner />
                    </div>
                  )}
                  {error.sku && (
                    <div className="invalid-feedback">{error.sku}</div>
                  )}
                </div>
                {success && <div className="text-success mt-2">{success}</div>}
              </div>
            </div>

            {/* Modal Footer */}
            <div className="modal-footer">
              <button
                className="btn btn-light-primary"
                style={{ marginRight: "auto" }}
                onClick={() => setIsShippingMarkModalOpen(true)}
              >
                {t("Shipping.Handover.Mark.Add")}
              </button>
              <button
                onClick={handleSubmit}
                id="submit-button"
                className="btn btn-primary"
                disabled={isSkuLoading}
              >
                {t("Common.Button.Submit")}
              </button>
              <button onClick={onClose} className="btn btn-secondary">
                {t("Common.Button.Cancel")}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Shipping Mark Modal */}
      {isShippingMarkModalOpen && (
        <ShippingMarkModal onClose={() => setIsShippingMarkModalOpen(false)} />
      )}
    </>
  );
};

export default ScanLocatorModal;
