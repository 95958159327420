import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { PageLink, PageTitle } from "../../core";
import { useMediaQuery } from "react-responsive";
import { ActionsCell } from "./components/ActionsCell";
import ListComponent from "src/app/components/list/NewList";
import "src/styles/css/product.css";
import ValuePack from "src/app/models/Prepacks";
import ScanLocatorModal from "./modal/ScanLocatorModal";
import ShippingMarkModal from "./modal/ShippingMarkModal";
import Order from "src/app/models/Order";
import ShippingLimitLabel from "../shiporder-export/order-list/components/ShippingLimitLabel";
import { format } from "date-fns";
import db from "src/db";
import ReprintModal from "./modal/ReprintModal";
import ImportStockModal from "./modal/ImportStockModal";

type Columns<T> = {
  title: string;
  field: keyof T;
  value: (props: { value: any }) => JSX.Element | Promise<JSX.Element>;
};

// A new component for rendering the product name
const ProductName = ({ name }: { name: string | undefined }) => {
  const isMobileView = useMediaQuery({ query: "(max-width: 900px)" });

  return (
    <>
      {name ? (
        <div
          className={
            isMobileView
              ? "m-0 p-0 d-flex justify-content-end"
              : "m-0 p-0 h-auto d-flex align-items-center justify-content-start fw-bolder"
          }
        >
          {name}
        </div>
      ) : (
        <span
          className="indicator-progress m-0 p-0 me-2"
          style={{ display: "block" }}
        >
          <span className="spinner-border spinner-border-sm align-middle m-0 p-0"></span>
        </span>
      )}
    </>
  );
};

/**
 * Table Value Pack That Showing Product Name, Product SKU, Order Quantity
 *
 * @returns {*}
 */
const ValuePackPage = () => {
  const { t } = useTranslation();
  const isMobileView = useMediaQuery({ query: "(max-width: 900px)" });

  const [isScanLocatorModalOpen, setIsScanLocatorModalOpen] =
    useState<boolean>(false);
  const [isShippingMarkModalOpen, setIsShippingMarkModalOpen] =
    useState<boolean>(false);
  const [isReprintModalOpen, setIsReprintModalOpen] = useState<boolean>(false);
  const [isImportStockModalOpen, setIsImportStockModalOpen] =
    useState<boolean>(false);
  const [isHovered, setIsHovered] = useState<any>([]);

  /**
   * Breadcrumbs for Collection Prepack
   *
   * @type {Array<PageLink>}
   */
  const usersBreadcrumbs: Array<PageLink> = [
    {
      title: t("Value.Pack.Breadcrumb"),
      path: "#",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ];

  /**
   * Default Column For Collection Prepack
   * Displaying Product Name, Product SKU, Order Quantity
   * @type {Columns<ValuePack>[]}
   */
  const defaultColumns: Columns<Order>[] = [
    {
      field: "itemList[0]?.itemName" as any,
      title: t("Value.Pack.ProductName"),
      value: (props: any) => {
        const productName = props.row?.original?.productName;
        return <ProductName name={productName} />;
      },
    },
    {
      field: "itemList[0]?.itemSKU" as any,
      title: t("Value.Pack.ProductSKU"),
      value: (props: any) => {
        return (
          <div
            className={
              isMobileView
                ? "m-0 p-0 d-flex justify-content-end"
                : "m-0 p-0 h-auto d-flex align-items-center justify-content-start fw-bolder"
            }
          >
            {props.row?.original?.productSKU}
          </div>
        );
      },
    },
    {
      field: "ordersn",
      title: t("ExportOrderProcess.TotalOrder"),
      value: (props: any) => {
        const id = props.row?.original?.productSKU;
        return (
          <div
            className={
              isMobileView
                ? "m-0 p-0 d-flex justify-content-end"
                : "m-0 p-0 h-auto d-flex align-items-center justify-content-start fw-bolder cursor-pointer"
            }
            onClick={() => {
              if (isHovered.includes(id)) {
                setIsHovered(isHovered.filter((item: any) => item !== id));
              } else {
                setIsHovered([...isHovered, id]);
              }
            }}
          >
            {isHovered.includes(id) ? (
              <div className="d-flex flex-column">
                {props.row?.original?.allOrders.map((order: any) => (
                  <p className="m-0 p-0" key={order}>
                    {order}
                  </p>
                ))}
              </div>
            ) : (
              props.row?.original?.totalOrders
            )}
            {isHovered.includes(id) ? (
              <i className="fas fa-eye-slash ms-2"></i>
            ) : (
              <i className="fas fa-eye ms-2"></i>
            )}
          </div>
        );
      },
    },
    {
      field: "itemList",
      title: t("Value.Pack.Storage"),
      value: (props: any) => {
        console.log(props.row?.original);
        const totalStock = props.row?.original?.storages.reduce(
          (acc: number, loc: any) => acc + loc.stock,
          0
        );
        const totalOrder = props.row?.original?.totalOrders;
        const warning = totalStock <= totalOrder * 2;
        const danger = totalStock <= totalOrder;
        return (
          <div
            className={
              isMobileView
                ? "m-0 p-0 d-flex justify-content-end"
                : "m-0 p-0 h-auto d-flex align-items-center justify-content-start fw-bolder cursor-pointer w-100"
            }
          >
            <div className="d-flex flex-column w-100">
              {totalStock > 0 ? (
                props.row?.original?.storages?.map((loc: any) => (
                  <p className="my-1 py-1 w-100" key={loc?.code}>
                    <span
                      className="my-1 me-2 w-40 py-2 px-2 rounded"
                      style={{
                        backgroundColor: danger
                          ? "#F8D7DA" // Danger (Merah Muda)
                          : warning
                          ? "#FFF3CD" // Warning (Kuning Muda)
                          : "#CCE5FF", // Primary (Biru Muda)
                        color: danger
                          ? "#721C24" // Danger (Merah Tua)
                          : warning
                          ? "#856404" // Warning (Kuning Tua)
                          : "#004085", // Primary (Biru Tua)
                      }}
                    >
                      <i
                        className="fas fa-box me-2"
                        style={{
                          color: danger
                            ? "#721C24" // Danger (Merah Tua)
                            : warning
                            ? "#856404" // Warning (Kuning Tua)
                            : "#004085", // Primary (Biru Tua)
                        }}
                      ></i>
                      {loc?.code}
                    </span>
                    <span
                      className={`my-1 w-40 py-2 px-2 rounded`}
                      style={{
                        backgroundColor: danger
                          ? "#F8D7DA" // Danger (Merah Muda)
                          : warning
                          ? "#FFF3CD" // Warning (Kuning Muda)
                          : "#CCE5FF", // Primary (Biru Muda)
                        color: danger
                          ? "#721C24" // Danger (Merah Tua)
                          : warning
                          ? "#856404" // Warning (Kuning Tua)
                          : "#004085", // Primary (Biru Tua)
                      }}
                    >
                      <i
                        className="fas fa-cubes me-2"
                        style={{
                          color: danger
                            ? "#721C24" // Danger (Merah Tua)
                            : warning
                            ? "#856404" // Warning (Kuning Tua)
                            : "#004085", // Primary (Biru Tua)
                        }}
                      ></i>
                      {loc?.stock}
                    </span>
                  </p>
                ))
              ) : (
                <p className="my-1 py-1 w-100">
                  <i className="fas fa-exclamation-triangle me-2"></i>
                  {t("Value.Pack.InsufficientStock")}
                </p>
              )}
            </div>
          </div>
        );
      },
    },
    {
      field: "marketplaceOrderCreatedAt",
      title: t("OrderList.OrderTime"),
      value: (props: any) => {
        return (
          <>
            <p className="p-0 m-0 fw-bolder" style={{ color: "#999999" }}>
              {props?.row?.original?.orderTime
                ? format(
                    new Date(props?.row?.original?.orderTime),
                    "dd/MM/yyyy"
                  )
                : " - "}
            </p>
            <p className="p-0 m-0">
              {props?.row?.original?.orderTime
                ? format(new Date(props?.row?.original?.orderTime), "HH:mm")
                : " - "}
            </p>
          </>
        );
      },
    },
    {
      field: "shipByDate",
      title: t("OrderList.ShippingLimit"),
      value: (props: any) => {
        return (
          <div
            className={
              isMobileView
                ? "m-0 p-0 d-flex justify-content-end fw-bolder"
                : "my-2 mx-0 p-0 d-flex justify-content-start fw-bolder"
            }
          >
            <ShippingLimitLabel
              shippingTimestamp={props?.row?.original?.shipByDate}
            />
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    const refetchButton = window.document.getElementById("button-refetch");
    if (refetchButton) {
      refetchButton.click();
    } else {
      console.warn("Refetch button not found!");
    }
  }, [isScanLocatorModalOpen]);

  const openScanLocatorModal = useCallback(
    () => setIsScanLocatorModalOpen(true),
    []
  );
  const openShippingMarkModal = useCallback(
    () => setIsShippingMarkModalOpen(true),
    []
  );
  const openReprintModal = useCallback(() => setIsReprintModalOpen(true), []);

  const openImportStockModal = useCallback(
    () => setIsImportStockModalOpen(true),
    []
  );

  return (
    <>
      <PageTitle
        breadcrumbs={usersBreadcrumbs}
        data-testid="value-pack-page-title"
      >
        {t("Value.Pack.Title")}
      </PageTitle>
      <ListComponent<Order>
        listName="value-pack"
        collection="orders"
        collectionGroup={false}
        columns={defaultColumns}
        selection={{
          headerSelection: false,
          rowSelection: false,
        }}
        pagination={[25, 50, 75, 100, 125, 150, 175, 200, 225, 250, 275, 300]}
        defaultFilters={[
          {
            label: "isPrepack",
            field: "isPrepack",
            type: "boolean",
            value: true,
          },
          {
            label: "printedLabelAt",
            field: "printedLabelAt",
            type: "null",
          },
          {
            label: "status",
            field: "status",
            type: "text",
            value: "READY_TO_SHIP",
          },
        ]}
        defaultSorting={[
          {
            field: "marketplaceOrderCreatedAt",
            label: "marketplaceOrderCreatedAt",
            direction: "asc",
          },
        ]}
        useFilter={[]}
        searching={{
          field: "ordersn",
          useFirestore: true,
          value: "",
          caseSensitive: false,
        }}
        extra={[
          // {
          //   label: "import_stock",
          //   value: (props: any) => {
          //     return (
          //       <>
          //         <button
          //           type="button"
          //           data-testid="btnImportStock"
          //           className="btn btn-success"
          //           onClick={openImportStockModal}
          //         >
          //           {t("Value.Pack.ImportStock")}
          //         </button>
          //       </>
          //     );
          //   },
          // },
          {
            label: "re_print_label",
            value: (props: any) => {
              return (
                <>
                  <button
                    type="button"
                    data-testid="btnScanLocator"
                    className="btn btn-warning text-dark"
                    onClick={openReprintModal}
                  >
                    {t("Value.Pack.Scan.Reprint")}
                  </button>
                </>
              );
            },
          },
          {
            label: "scan_locator",
            value: (props: any) => {
              return (
                <>
                  <button
                    type="button"
                    data-testid="btnScanLocator"
                    className="btn btn-primary"
                    onClick={openScanLocatorModal}
                  >
                    {t("Value.Pack.Scan.Locator")}
                  </button>
                </>
              );
            },
          },
          // {
          //   label: "create_shipping",
          //   value: (props: any) => {
          //     return (
          //       <>
          //         <button
          //           type="button"
          //           data-testid="btnCreateShipping"
          //           className="btn btn-primary"
          //           onClick={openShippingMarkModal}
          //         >
          //           {t("Shipping.Handover.Mark.Add")}
          //         </button>
          //       </>
          //     );
          //   },
          // },
          {
            label: "total_order",
            value: (props: any) => {
              return (
                <button className="btn btn-secondary text-gray-700">
                  <p className="m-0 p-0 fw-bolder">
                    {props?.data?.allCount ?? 0} {t("Menu.Order")}
                  </p>
                </button>
              );
            },
          },
        ]}
      />

      {isScanLocatorModalOpen && (
        <ScanLocatorModal onClose={() => setIsScanLocatorModalOpen(false)} />
      )}
      {isReprintModalOpen && (
        <ReprintModal onClose={() => setIsReprintModalOpen(false)} />
      )}
      {/* {isImportStockModalOpen && (
        <ImportStockModal onClose={() => setIsImportStockModalOpen(false)} />
      )} */}
      {isShippingMarkModalOpen && (
        <ShippingMarkModal onClose={() => setIsShippingMarkModalOpen(false)} />
      )}
    </>
  );
};

export default ValuePackPage;
