import clsx from "clsx";
import firebase from "firebase/compat/app";
import moment from "moment";
import { FC, Fragment, useEffect, useState } from "react";
import Account from "../../../../../../../../models/Account";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import { Dropdown, Modal } from "react-bootstrap";
import db from "../../../../../../../../../db";
import { useFirestoreTable } from "../../../context/TableContext";
import {
  connectActionAccount,
  deleteAccount,
  disconnectActionAccount,
} from "../../../../../../../../../db/serviceAccount";
import {
  KTCard,
  toAbsoluteUrl,
} from "../../../../../../../../../resources/helpers";
import Marketplace from "../../../../../../../../../util/Marketplace";
import {
  requestConnectShopeeChatURL,
  requestConnectShopeeURL,
  requestDisconnectShopeeURL,
  requestLazadaChatURL,
  requestLazadaURL,
  requestResendCodeShopeeURL,
} from "../../../../../../../../../api/server/marketplace";
import { useMediaQuery } from "react-responsive";
import React from "react";
import MarketplaceShopEdit from "../../MarketplaceShopEdit";

type ActionCellProp = {
  data: any;
};

const MarketplaceActionCell: FC<ActionCellProp> = ({ data }) => {
  const { t } = useTranslation();
  const nav = useNavigate();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const status = data.isActive;
  const { refreshData, isFetching, updateTableState, isLoading } =
    useFirestoreTable();
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [isReassureDeleteOpen, setIsReassureDeleteOpen] = useState(false);
  const [isAppTypeOpen, setIsAppTypeOpen] = useState(false);
  const onReassureDeleteClose = () => setIsReassureDeleteOpen(false);
  const onAppTypeClose = () => setIsAppTypeOpen(false);
  const [isReassureDisconnectOpen, setIsReassureDisconnectOpen] =
    useState(false);
  const onReassureDisconnectClose = () => setIsReassureDisconnectOpen(false);

  let connected_erp = false;
  let connected_chat = false;
  let expired_accesstoken_erp = false;
  let expired_accesstoken_chat = false;
  if (data.type === "shopee") {
    const shopee_exp = data[`${data.type}_expiredIn`];
    const shopee_expMilliseconds =
      shopee_exp?.seconds * 1000 + shopee_exp?.nanoseconds / 1000000;
    const shopee_expDate = new Date(shopee_expMilliseconds);
    const shopee_erp_con = data?.credentials?.erp?.connectedAt;
    const shopee_chat_con = data?.credentials?.chat?.connectedAt;
    const shopee_chat_exp = data?.credentials?.chat?.expiredIn;
    const shopee_chat_expMilliseconds =
      shopee_chat_exp?.seconds * 1000 + shopee_chat_exp?.nanoseconds / 1000000;
    const shopee_chat_expDate = new Date(shopee_chat_expMilliseconds);
    const currentDate = new Date();
    if (shopee_erp_con) {
      connected_erp = true;
    }
    if (shopee_chat_con) {
      connected_chat = true;
    }
    if (shopee_expDate < currentDate) {
      expired_accesstoken_erp = true;
    }
    if (shopee_chat_expDate < currentDate) {
      expired_accesstoken_chat = true;
    }
  }
  if (data.type === "tokopedia") {
    const tokopedia_exp = data[`${data.type}_accessTokenExpired`];
    const tokopedia_expMilliseconds =
      tokopedia_exp?.seconds * 1000 + tokopedia_exp?.nanoseconds / 1000000;
    const tokopedia_expDate = new Date(tokopedia_expMilliseconds);
    const currentDate = new Date();
    connected_erp = true;
    connected_chat = true;
    if (tokopedia_expDate < currentDate) {
      expired_accesstoken_erp = true;
      expired_accesstoken_chat = true;
    }
  }
  if (data.type === "lazada") {
    const lazerp_exp = data?.credentials?.erp?.accessTokenExpired;
    const lazerp_expMilliseconds =
      lazerp_exp?.seconds * 1000 + lazerp_exp?.nanoseconds / 1000000;
    const lazerp_expDate = new Date(lazerp_expMilliseconds);
    const lazchat_exp = data?.credentials?.chat?.accessTokenExpired;
    const lazchat_expMilliseconds =
      lazchat_exp?.seconds * 1000 + lazchat_exp?.nanoseconds / 1000000;
    const lazchat_expDate = new Date(lazchat_expMilliseconds);
    const currentDate = new Date();
    if (!!lazchat_expDate) {
      connected_chat = true;
    }
    if (!!lazerp_expDate) {
      connected_erp = true;
    }
    if (lazerp_expDate < currentDate) {
      expired_accesstoken_erp = true;
    }
    if (lazchat_expDate < currentDate) {
      expired_accesstoken_chat = true;
    }
  }
  if (data.type === "tiktok") {
    const tiktok_exp = data?.credentials?.erp?.accessTokenExpired;
    const tiktok_expMilliseconds =
      tiktok_exp?.seconds * 1000 + tiktok_exp?.nanoseconds / 1000000;
    const tiktok_expDate = new Date(tiktok_expMilliseconds);
    const tiktok_erp_con = data?.credentials?.erp?.connectedAt;
    const tiktok_chat_con = data?.credentials?.chat?.connectedAt;
    const tiktok_chat_exp = data?.credentials?.chat?.accessTokenExpired;
    const tiktok_chat_expMilliseconds =
      tiktok_chat_exp?.seconds * 1000 + tiktok_chat_exp?.nanoseconds / 1000000;
    const tiktok_chat_expDate = new Date(tiktok_chat_expMilliseconds);
    const currentDate = new Date();
    if (tiktok_erp_con) {
      connected_erp = true;
    }
    if (tiktok_chat_con) {
      connected_chat = true;
    }
    if (tiktok_expDate < currentDate) {
      expired_accesstoken_erp = true;
    }
    if (tiktok_chat_expDate < currentDate) {
      expired_accesstoken_chat = true;
    }
  }

  const connectHandler = async (data: any) => {
    let reconnect = "";
    if (connected_erp === true && connected_chat === false) {
      if (connected_erp === true && expired_accesstoken_erp === true) {
        reconnect = "erp";
      } else {
        reconnect = "chat";
      }
    }
    if (connected_erp === false && connected_chat === true) {
      if (connected_chat === true && expired_accesstoken_chat === true) {
        reconnect = "chat";
      } else {
        reconnect = "erp";
      }
    }
    if (connected_erp === true && connected_chat === true) {
      if (expired_accesstoken_chat === true) {
        reconnect = "chat";
      }
      if (expired_accesstoken_erp === true) {
        reconnect = "erp";
      }
    }

    let settingsArray: firebase.firestore.DocumentData[] = [];
    await db
      .collection("settings")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((doc) => {
          let datadoc = doc.data();
          datadoc["id"] = doc.id;
          settingsArray.push(datadoc);
        });
      })
      .catch((error) => {
        console.log("Error getting documents: ", error);
      });

    if (settingsArray?.length > 0) {
      if (data?.type === "shopee") {
        if (reconnect === "chat") {
          const shopee_chat_parnerID = settingsArray?.find(
            (item) => item.id === "Shopee_Chat"
          )?.partnerId;
          const shopee_chat_parnerKey = settingsArray?.find(
            (item) => item.id === "Shopee_Chat"
          )?.partnerKey;
          const urlFromServer = await requestConnectShopeeChatURL(
            shopee_chat_parnerID,
            shopee_chat_parnerKey,
            data?.id
          );
          const callbackURL = urlFromServer?.url;
          if (callbackURL) {
            window.open(callbackURL, "_blank");
          }
        } else {
          const shopee_erp_parnerID = settingsArray?.find(
            (item) => item.id === "Shopee"
          )?.partnerId;
          const shopee_erp_parnerKey = settingsArray?.find(
            (item) => item.id === "Shopee"
          )?.partnerKey;
          const urlFromServer = await requestConnectShopeeURL(
            shopee_erp_parnerID,
            shopee_erp_parnerKey,
            data?.id
          );
          const callbackURL = urlFromServer?.url;
          if (callbackURL) {
            window.open(callbackURL, "_blank");
          }
        }
      }
      if (data?.type === "lazada") {
        if (reconnect === "chat") {
          const urlFromServer = await requestLazadaChatURL("Lazada", data?.id);
          const callbackURL = urlFromServer?.login_url;
          if (callbackURL) {
            window.open(callbackURL, "_blank");
          }
        } else {
          const urlFromServer = await requestLazadaURL("Lazada", data?.id);
          const callbackURL = urlFromServer?.login_url;
          if (callbackURL) {
            window.open(callbackURL, "_blank");
          }
        }
      }
      if (data?.type === "tokopedia") {
        alert(
          "Tokopedia ERP & Chat Service not available now, please contact admin"
        );
      }
    }
  };

  const disconnectHandler = async (data: any, appType: string) => {
    console.log(appType, "apetipe bypass");
    if (data.type === "shopee") {
      const urlFromServer = await requestDisconnectShopeeURL(
        // data[`${data.type}_partnerID`],
        // data[`${data.type}_partnerKey`]
        data.credentials[`${appType}`].partnerID,
        data.credentials[`${appType}`].partnerKey,
        appType
      );
      const callbackURL = urlFromServer?.url;
      if (callbackURL) {
        window.open(callbackURL, "_blank");
      }
    }
  };

  const deleteAction = async () => {
    await deleteAccount(data.id);
    let el = document.getElementById(data.id);
    el?.classList.remove("show");
    refreshData();
    setDropdownVisible(false);
    onReassureDeleteClose();
  };

  const disconnectAction = async (appType: string) => {
    await disconnectHandler(data, appType);
    setDropdownVisible(false);
  };

  const connectAction = async () => {
    await connectHandler(data);
    setDropdownVisible(false);
  };

  useEffect(() => {
    const EventHandler = (event: any) => {
      if (event.data === "ServerClosed") {
        window.location.reload();
      }
    };
    window.addEventListener("message", EventHandler);
    return () => {
      window.removeEventListener("message", EventHandler);
    };
  }, []);

  const reassureDeleteOpen = () => {
    setIsReassureDeleteOpen(true);
  };

  const reassureDisconnectOpen = () => {
    setIsReassureDisconnectOpen(true);
  };

  return (
    <>
      <Dropdown
        id={data.id}
        show={dropdownVisible}
        onToggle={(isOpen) => setDropdownVisible(isOpen)}
      >
        {isTabletOrMobile ? (
          <Dropdown.Toggle
            variant="transparent"
            id="dropdown-toggle"
            className="no-caret"
            bsPrefix="bi bi-three-dots-vertical fs-1 pe-3"
            // as={CustomToggle}
          >
            {/* <i className="bi bi-three-dots-vertical fs-1 pe-3"></i> */}
          </Dropdown.Toggle>
        ) : (
          <>
            <Dropdown.Toggle
              variant="secondary"
              id="dropdown-toggle"
              data-testid="dropdownToggleMarketplaceAction"
            >
              {t("Marketplace.Table.Header.Action")}
            </Dropdown.Toggle>
          </>
        )}
        <Dropdown.Menu
          className="bg-light text-center"
          data-testid="dropdownMenuMarketplaceActionCell"
        >
          {(connected_chat === false ||
            connected_erp === false ||
            expired_accesstoken_chat === true ||
            expired_accesstoken_erp === true) && (
            <Link
              className="dropdown-item btn btn-black btn-sm"
              to="#"
              onClick={connectAction}
              data-testid="connectButton"
            >
              {expired_accesstoken_chat || expired_accesstoken_erp
                ? (() => {
                    let message = t("Marketplace.Action.Reconnect");
                    if (connected_erp && expired_accesstoken_erp) {
                      message += " ERP";
                    }
                    if (connected_chat && expired_accesstoken_chat) {
                      if (connected_erp && expired_accesstoken_erp) {
                        message += " &";
                      }
                      message += " Chat";
                    }
                    return message;
                  })()
                : (() => {
                    let message = t("Marketplace.Action.Connect");
                    if (!connected_erp) {
                      message += " ERP";
                    }
                    if (!connected_chat) {
                      if (!connected_erp) {
                        message += " &";
                      }
                      message += " Chat";
                    }
                    return message;
                  })()}
            </Link>
          )}

          {connected_erp === true &&
            expired_accesstoken_erp === false &&
            data.type === "shopee" && (
              <Link
                className="dropdown-item btn btn-black btn-sm"
                to="#"
                onClick={() => {
                  disconnectAction("erp");
                }}
                data-testid="disconnectButton"
              >
                {t("Marketplace.Action.Disconnect")} ERP
              </Link>
            )}

          {connected_chat === true &&
            expired_accesstoken_chat === false &&
            data.type === "shopee" && (
              <Link
                className="dropdown-item btn btn-black btn-sm"
                to="#"
                onClick={() => {
                  disconnectAction("chat");
                }}
                data-testid="disconnectButton"
              >
                {t("Marketplace.Action.Disconnect")} Chat
              </Link>
            )}

          <Link
            className="dropdown-item btn btn-black btn-sm"
            to={`/setting/marketplace/edit/${data.id}`}
            data-testid="editButton"
          >
            {t("Marketplace.Button.Edit")}
          </Link>
          <Link
            className="dropdown-item btn btn-black btn-sm"
            to="#"
            onClick={() => {
              if (
                data.connectedAt &&
                data.isActive === true &&
                data.type === "shopee"
              ) {
                // masih konek
                reassureDisconnectOpen();
              } else {
                // sudah tidak konek
                reassureDeleteOpen();
              }
            }}
            data-testid="deleteButton"
          >
            {t("Marketplace.Action.Delete")}
          </Link>
        </Dropdown.Menu>
      </Dropdown>

      {isReassureDeleteOpen === true && (
        <Modal
          animation
          centered
          show={isReassureDeleteOpen === true}
          data-testid="modalReassureDeleteMarketplaceAction"
          // size="sm"
          scrollable={false}
          onHide={() => onReassureDeleteClose && onReassureDeleteClose()}
        >
          <Modal.Header closeButton className="bg-light">
            <Modal.Title data-testid="modal-title">
              {t("Marketplace.Modal.ReassureDelete.Title")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="bg-light">
            <div className="fs-4">
              <p>{t("Marketplace.Modal.ReassureDelete.Body")}</p>
            </div>
          </Modal.Body>
          <Modal.Footer className="bg-light">
            <button
              type="button"
              data-testid="modalCancelButton"
              className="btn btn-outline-primary w-15 text-center"
              style={{
                border: "0.5px solid #189ffb",
              }}
              onClick={() => onReassureDeleteClose && onReassureDeleteClose()}
            >
              {t("Marketplace.Modal.ReassureDelete.Cancel")}
            </button>
            <button
              type="button"
              data-testid="modalDeleteButton"
              className="btn btn-primary w-30"
              onClick={() => {
                deleteAction();
              }}
            >
              {t("Marketplace.Modal.ReassureDelete.Yes")}
            </button>
          </Modal.Footer>
        </Modal>
      )}

      {isReassureDisconnectOpen === true && (
        <Modal
          animation
          centered
          show={isReassureDisconnectOpen === true}
          data-testid="modalReassureDisconnectMarketplaceAction"
          // size="sm"
          scrollable={false}
          onHide={() =>
            onReassureDisconnectClose && onReassureDisconnectClose()
          }
        >
          <Modal.Header closeButton className="bg-light">
            <Modal.Title data-testid="modal-title">
              {t("Marketplace.Modal.ReassureDelete.Title")}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="bg-light">
            <div className="fs-4">
              <p>{t("Marketplace.Modal.ReassureDisconnect.Body")}</p>
            </div>
          </Modal.Body>
          <Modal.Footer className="bg-light">
            <button
              type="button"
              className="btn btn-outline-primary w-15 text-center"
              style={{
                border: "0.5px solid #189ffb",
              }}
              data-testid="modalCancelButton"
              onClick={() =>
                onReassureDisconnectClose && onReassureDisconnectClose()
              }
            >
              {/* {t("Marketplace.Modal.ReassureDelete.Cancel")} */}
              {t("Marketplace.Modal.ReassureDisconnect.Yes")}
            </button>
            {/* <button
              type="button"
              data-testid="modalDisconnectButton"
              className="btn btn-primary w-30"
              onClick={() => disconnectAction()}
            >
              {t("Marketplace.Modal.ReassureDisconnect.Yes")}
            </button> */}
          </Modal.Footer>
        </Modal>
      )}

      {/* {isAppTypeOpen === true && (
        <Modal
          animation
          centered
          show={isAppTypeOpen === true}
          data-testid="modalAppTypeAction"
          // size="sm"
          scrollable={false}
          onHide={() =>
            onAppTypeClose && onAppTypeClose()
          }
        >
          <Modal.Header closeButton className="bg-light">
            <Modal.Title data-testid="modal-title">
              Generate New Access Token
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="bg-light">
            <div className="fs-4">
              <p>Application Type</p>
            </div>
          </Modal.Body>
          <Modal.Footer className="bg-light">
            <button
              type="button"
              className="btn btn-outline-primary w-15 text-center"
              style={{
                border: "0.5px solid #189ffb",
              }}
              data-testid="modalCancelButton"
              onClick={() =>
                onReassureDisconnectClose && onReassureDisconnectClose()
              }
            >
              {t("Common.No")}
            </button>
            <button
              type="button"
              data-testid="modalDisconnectButton"
              className="btn btn-primary w-30"
              onClick={() => disconnectAction()}
            >
              {t("Common.Yes")}
            </button>
          </Modal.Footer>
        </Modal>
      )} */}
    </>
  );
};

export default MarketplaceActionCell;
