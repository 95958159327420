import { FC, useState } from "react";
import { Modal, Offcanvas } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { KTCard, toAbsoluteUrl } from "../../../../../../../resources/helpers";
import Marketplace, {
  ListMarketplace,
} from "../../../../../../../util/Marketplace";
import TargetModalConnect from "./MarketplaceTargetModalConnect";
import {
  IResponseFormat,
  requestLazadaURL,
  requestLazadaChatURL,
  requestShopeeURL,
  requestShopeeChatURL,
  requestTiktokUrl,
} from "../../../../../../../api/server/marketplace";
import { useMediaQuery } from "react-responsive";

const TargetModalSelect: FC<{
  isOpen?: boolean;
  onClose?: () => void;
  onAction?: any;
}> = ({ isOpen, onClose, onAction }) => {
  const { t } = useTranslation();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const includedMarketplaces = ListMarketplace.filter(
    (item) =>
      item.name === "Shopee" ||
      item.name === "Shopee_Chat" ||
      item.name === "Lazada" ||
      item.name === "Lazada_Chat" ||
      item.name === "TiktokShop" ||
      item.name === "Tokopedia" ||
      item.name === "Blibli" ||
      item.name === "Bukalapak"
  );
  const openModal = () => {
    onAction && onAction();
  };

  const [targetModalConnectOpen, setTargetModalConnectOpen] = useState(false);
  const onTargetModalConnectClose = () => {
    setTargetModalConnectOpen(false);
    onAction();
  };

  const [marketplaceName, setMarketplaceName] = useState<ModalConnectProp>();
  const [waitingURL, setWaitingURL] = useState<boolean>(false);

  const callServerHandler = async (market: Marketplace) => {
    console.log(market, "ini callServerHandler market apa moop");
    setWaitingURL(true);
    let urlFromServer;
    if (market.name === "Shopee") {
      let fetching = await requestShopeeURL(market.name);
      urlFromServer = fetching?.url;
    }
    if (market.name === "Shopee_Chat") {
      let fetching = await requestShopeeChatURL(market.name);
      urlFromServer = fetching?.url;
      console.log(fetching, "testing fet");
    }
    if (market.name === "Lazada") {
      let fetching = await requestLazadaURL(market.name);
      urlFromServer = fetching?.login_url;
    }
    if (market.name === "Lazada_Chat") {
      let fetching = await requestLazadaChatURL(market.name);
      urlFromServer = fetching?.login_url;
    }
    if (market.name === "TiktokShop") {
      let fetching = await requestTiktokUrl(market.name);
      urlFromServer = fetching?.url;
    }
    console.log(`URL Connect from ${market.name}: ${urlFromServer}`);
    if (urlFromServer) {
      setWaitingURL(false);
      setTargetModalConnectOpen(true);
      setMarketplaceName({ name: market.name, callbackURL: urlFromServer });
    } else {
      alert("Error server");
      setWaitingURL(false);
      setTargetModalConnectOpen(false);
    }
  };

  return (
    <>
      {isOpen && !targetModalConnectOpen && (
        <>
          {isTabletOrMobile ? (
            <Offcanvas
              show={isOpen}
              onHide={() => onClose && onClose()}
              placement="bottom"
              style={{
                borderTopLeftRadius: "30px",
                borderTopRightRadius: "30px",
              }}
              className="h-auto"
            >
              <Offcanvas.Header closeButton className="border-bottom ms-5 me-5">
                <Offcanvas.Title className="fs-2 pt-2">
                  {t("Marketplace.Modal.Header.SelectMarketplace")}
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body className="h-auto">
                <KTCard>
                  <ul className="list-unstyled d-flex flex-wrap mb-20 px-3 justify-content-between">
                    {includedMarketplaces.map((marketplace) => (
                      <li key={marketplace.name} className="mb-5">
                        <button
                          className="border-0 rounded p-3 w-100 text-center"
                          onClick={() => callServerHandler(marketplace)}
                          style={{
                            backgroundColor: "#E4E6EF",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                          }}
                        >
                          <img
                            className="h-90px w-100px mb-2"
                            src={toAbsoluteUrl(
                              `/media/logos/marketplace/${marketplace.name.toLowerCase()}.png`
                            )}
                            alt={marketplace.name}
                          />
                          <div className="fs-5">
                            {marketplace.name.replace("_", " ")}
                          </div>
                        </button>
                      </li>
                    ))}
                  </ul>
                </KTCard>
              </Offcanvas.Body>
            </Offcanvas>
          ) : (
            <>
              <Modal
                animation
                centered
                show={isOpen}
                onHide={() => onClose && onClose()}
                size="lg"
                scrollable={false}
              >
                <Modal.Header closeButton className="bg-light">
                  <Modal.Title data-testid="modal-title">
                    {t("Marketplace.Modal.Header.SelectMarketplace")}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-light">
                  <KTCard>
                    <ul className="row">
                      {includedMarketplaces.map((marketplace) => (
                        <li
                          className="col-sm-2 list-unstyled px-2 pt-5 pe-2"
                          key={marketplace.name}
                        >
                          <button
                            className="border-0 rounded p-2"
                            onClick={() => callServerHandler(marketplace)}
                            data-testid="marketplace-TargetModalSelect"
                          >
                            <img
                              className="h-80px w-80px mb-2"
                              src={toAbsoluteUrl(
                                `/media/logos/marketplace/${marketplace.name.toLowerCase()}.png`
                              )}
                              style={{
                                objectFit: "contain",
                                width: "100%",
                                height: "100%",
                              }}
                              alt={`${marketplace.name}.png`}
                            />
                            <div style={{ textAlign: "center" }}>
                              {marketplace.name.replace("_", " ")}
                            </div>
                          </button>
                        </li>
                      ))}
                    </ul>
                    {/* <div className="spinner-border spinner-border-lg"></div> */}
                  </KTCard>
                </Modal.Body>
                <Modal.Footer className="bg-white" />
              </Modal>
            </>
          )}
        </>
      )}

      {waitingURL && (
        <Modal animation centered show={waitingURL} size="sm">
          <KTCard>
            <div className="d-flex justify-content-center m-10">
              <div className="spinner-border spinner-border-lg"></div>
            </div>
          </KTCard>
        </Modal>
      )}

      {targetModalConnectOpen && (
        <TargetModalConnect
          isOpen={targetModalConnectOpen}
          onClose={onTargetModalConnectClose}
          onAction={onTargetModalConnectClose}
          marketplaceData={marketplaceName}
        />
      )}
    </>
  );
};

export type ModalConnectProp = {
  name: string;
  callbackURL: string;
};

export default TargetModalSelect;
