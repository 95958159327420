import axios from "axios";
import * as ApiSettingService from "../../db/serviceApiSetting";

/**
 * Send a request to scan a barcode for prepacking
 * @param code - an optional number, 1 for handle input locator code
 * @param locator - an optional string, the locator code
 * @param sku - an optional string, the sku of the product
 * @returns a Promise that resolves to the response from the server
 */
export const scanBarcode = async (
  code?: number,
  locator?: string,
  sku?: string
) => {
  const accountAccess = await ApiSettingService.getAccess();
  if (!accountAccess) {
    console.error("Failed To Get Access Token");
    return {
      statusCode: 401,
      error: {
        code: "ACCESS_TOKEN_ERROR",
        message: "Failed to get access token",
      },
    };
  }

  const token = accountAccess?.accessToken;

  const url = `${process.env.REACT_APP_SERVER_URL}/prepacks/scanBarcode`;

  const requestBody = {
    code,
    locator,
    sku,
  };

  try {
    const response = await axios.post(url, requestBody, {
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    if (response && response?.status !== 200) {
      return {
        statusCode: response.status,
        error: {
          code: "SERVER_ERROR",
          message: response.data.message || "An error occurred",
        },
      };
    }

    return {
      statusCode: 200,
      message: response.data.message,
      data: response.data,
    };
  } catch (error: any) {
    if (error?.response && error?.response?.status === 400) {
      console.error(
        "Error sending request for scan barcode",
        error?.response?.data
      );
      return {
        statusCode: 400,
        error: {
          code: error?.response?.data?.code || "BAD_REQUEST",
          message: error?.response?.data?.message || "Bad request",
        },
      };
    }

    return {
      statusCode: 500,
      error: {
        code: error?.code || "SERVER_ERROR",
        message: error?.message || "An unexpected error occurred",
      },
    };
  }
};

export const reForwardOrderInCancel = async (orderId: string) => {
  const accountAccess = await ApiSettingService.getAccess();
  if (!accountAccess) {
    console.error("Failed To Get Access Token");
    return {
      statusCode: 401,
      error: {
        code: "ACCESS_TOKEN_ERROR",
        message: "Failed to get access token",
      },
    };
  }

  const token = accountAccess?.accessToken;

  const url = `${process.env.REACT_APP_SERVER_URL}/playground/bulkParseReForwardVP`;

  const requestBody = [orderId];

  try {
    const response = await axios.post(url, requestBody, {
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    if (response && response?.status !== 200) {
      return {
        statusCode: response.status,
        error: {
          code: "SERVER_ERROR",
          message: response.data.message || "An error occurred",
        },
      };
    }

    return {
      statusCode: 200,
      message: response.data.message,
      data: response.data,
    };
  } catch (error: any) {
    if (error?.response && error?.response?.status === 400) {
      console.error(
        "Error sending request for reForwardOrder",
        error?.response?.data
      );
      return {
        statusCode: 400,
        error: {
          code: error?.response?.data?.code || "BAD_REQUEST",
          message: error?.response?.data?.message || "Bad request",
        },
      };
    }

    return {
      statusCode: 500,
      error: {
        code: error?.code || "SERVER_ERROR",
        message: error?.message || "An unexpected error occurred",
      },
    };
  }
};

export const reForwardOrder = async (orderId: string) => {
  const accountAccess = await ApiSettingService.getAccess();
  if (!accountAccess) {
    console.error("Failed To Get Access Token");
    return {
      statusCode: 401,
      error: {
        code: "ACCESS_TOKEN_ERROR",
        message: "Failed to get access token",
      },
    };
  }

  const token = accountAccess?.accessToken;

  const url = `${process.env.REACT_APP_SERVER_URL}/playground/bulkParseVPForward`;

  const requestBody = [orderId];

  try {
    const response = await axios.post(url, requestBody, {
      headers: {
        // Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    if (response && response?.status !== 200) {
      return {
        statusCode: response.status,
        error: {
          code: "SERVER_ERROR",
          message: response.data.message || "An error occurred",
        },
      };
    }

    return {
      statusCode: 200,
      message: response.data.message,
      data: response.data,
    };
  } catch (error: any) {
    if (error?.response && error?.response?.status === 400) {
      console.error(
        "Error sending request for reForwardOrder",
        error?.response?.data
      );
      return {
        statusCode: 400,
        error: {
          code: error?.response?.data?.code || "BAD_REQUEST",
          message: error?.response?.data?.message || "Bad request",
        },
      };
    }

    return {
      statusCode: 500,
      error: {
        code: error?.code || "SERVER_ERROR",
        message: error?.message || "An unexpected error occurred",
      },
    };
  }
};

async function printLabels(fileUrl: string, orderId: string) {
  try {
    const accountPush = await ApiSettingService.getPush();
    if (!accountPush) {
      return {
        statusCode: 401,
        error: {
          code: "ACCESS_ERROR",
          message: "Failed to get access push",
        },
      };
    }

    const baseUrlPrinter = accountPush?.printer?.url;
    if (!baseUrlPrinter) {
      return {
        statusCode: 401,
        error: {
          code: "ACCESS_ERROR",
          message: "Failed to get printer url",
        },
      };
    }

    return new Promise((resolve, reject) => {
      const socket = new WebSocket("ws://192.168.10.24:8080");

      // Set up timeout to handle connection issues
      const connectionTimeout = setTimeout(() => {
        socket.close();
        reject({
          statusCode: 504,
          error: {
            code: "CONNECTION_TIMEOUT",
            message: "WebSocket connection timed out",
          },
        });
      }, 2000); // 2 seconds timeout

      socket.onopen = () => {
        clearTimeout(connectionTimeout);

        // Prepare order data for download and print
        const orderData = {
          orderId,
          fileUrl: fileUrl,
          filePath: `../../public/files/shippingLabels/${orderId}.pdf`,
          printed: false,
        };

        // Send download action
        socket.send(
          JSON.stringify({
            action: "download",
            orderData,
          })
        );

        // Send print action
        socket.send(
          JSON.stringify({
            action: "print",
            orderData,
          })
        );
      };

      socket.onmessage = (event) => {
        try {
          const response = JSON.parse(event.data);
          // Handle potential response from server
          if (response.status === "success") {
            resolve({
              statusCode: 200,
              message: "Labels printed successfully",
            });
            socket.close();
          } else {
            reject({
              statusCode: 500,
              error: {
                code: "PRINT_ERROR",
                message: response.message || "Failed to print labels",
              },
            });
            socket.close();
          }
        } catch (parseError) {
          reject({
            statusCode: 500,
            error: {
              code: "PARSE_ERROR",
              message: "Failed to parse server response",
            },
          });
          socket.close();
        }
      };

      socket.onerror = (error) => {
        clearTimeout(connectionTimeout);
        reject({
          statusCode: 500,
          error: {
            code: "WEBSOCKET_ERROR",
            message: "WebSocket connection error",
            details: error,
          },
        });
      };

      socket.onclose = (event) => {
        clearTimeout(connectionTimeout);
        if (!event.wasClean) {
          reject({
            statusCode: 500,
            error: {
              code: "CONNECTION_CLOSED",
              message: "WebSocket connection closed unexpectedly",
            },
          });
        }
      };
    });
  } catch (error) {
    return {
      statusCode: 500,
      error: {
        code: "UNKNOWN_ERROR",
        message:
          error instanceof Error
            ? error.message
            : "An unexpected error occurred",
      },
    };
  }
}
