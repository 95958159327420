interface Marketplace {
  name: string;
  isDisabled: boolean;
}

export const ListMarketplace: Marketplace[] = [
  { name: "Shopee", isDisabled: false },
  { name: "Shopee_Chat", isDisabled: false },
  { name: "Lazada", isDisabled: false },
  { name: "Lazada_Chat", isDisabled: false },
  { name: "TiktokShop", isDisabled: true },
  { name: "Tokopedia", isDisabled: true },
  { name: "Blibli", isDisabled: true },
  { name: "Bukalapak", isDisabled: true },
  { name: "Tiktok", isDisabled: true },
  { name: "JDID", isDisabled: true },
  { name: "Bhinneka", isDisabled: true },
  { name: "Orami", isDisabled: true },
];
export const MarketplaceAPI = () => {
  const totalMarketplace = Object.keys("ListMarketplace").length;
  console.log("jumlah marketplace : " + totalMarketplace);
  return totalMarketplace;
};

export default Marketplace;
