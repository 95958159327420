import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import Order from "src/app/models/Order";

export const downloadInvoiceAsPDF = (data: Order) => {
  const modalContent = document.getElementById("invoice-modal-content");

  if (!modalContent) return;

  html2canvas(modalContent, { scale: 2 }).then((canvas) => {
    const imgData = canvas.toDataURL("image/png");
    const pdf = new jsPDF("p", "mm", "a4");
    const imgWidth = 210; // A4 width in mm
    const imgHeight = (canvas.height * imgWidth) / canvas.width;

    pdf.addImage(imgData, "PNG", 0, 0, imgWidth, imgHeight);
    pdf.save(`invoice_${data.ordersn}.pdf`);
  });
};
