import { createRef } from "../../../db/connection";
import * as Log from "src/util/SDayslogger"

export const LCName = {
  User: "User",
  SessionID: "SessionID",
  SessionCreated: "SessionCreated",
  SessionToken: "SessionToken",
  Messages: "Message_",
  SelectedChat: "SelectedChat",
  Account: "Account_",
  CompanyID: "CID",
  Notification: "Notif",
  isHaveNotif: "isHaveNotif",
  NotificationToken: "NotificationToken",
  UserID: "UID",
  //UserCompanies model
  SyncRes: "syncResult",
  CompanyList: "CompanyList",
  AccountList: "AccountList_",
  ChannelList: "Channel",
  Client: "Client",
  UserData: "UserData",
  Failover: "FailOver"
};

export const getUserId = () => {
  const user = getItemLC(LCName.User);
  if (!user) return;
  return user.uid;
};

export const getClientId = () => {
  const client = getItemLC(LCName.Client);
  if (!client) return;
  return client.id;
};

export const getClientRef = () => {
  const client = getItemLC(LCName.Client);
  if (client) {
    return createRef("clients", client.id);
  }
  return null;
};

export function setItemLCWithExpiry(key: string, value: any, ttl: number) {
  const now = new Date();
  const item = {
    value: value,
    expiry: now.getTime() + ttl * 24 * 60 * 60 * 1000,
  };
  localStorage.setItem(key, JSON.stringify(item));
}

export function setItemExpiry(key: string, value: any, ttl: number) {
  const now = new Date();
  const item = {
    value: value,
    expiry: now.getTime() + ttl * 24 * 60 * 60 * 1000,
  };
  localStorage.setItem(key, JSON.stringify(item));
}

export function getItemLC(key: string) {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }

  const item = JSON.parse(itemStr);
  if (item.hasOwnProperty("expiry")) {
    const now = new Date();
    if (now.getTime() > item.expiry) {
      localStorage.removeItem(key);
      return null;
    }
    return item.value;
  } else {
    return item.value;
  }
}

export function removeLC(key: string) {
  localStorage.removeItem(key);
}

export function setItemLC(key: string, value: any) : boolean {
  const item = {
    value: value,
  };

  try {
    localStorage.setItem(key, JSON.stringify(item));
  } catch (e) {
    if (e instanceof DOMException && e.name === "QuotaExceededError") {
      Log.warning(`Localstorage: ${JSON.stringify(localStorage).length / 1024}KB used`);
      Log.error(`Storage limit exceeded when saving data with key ${key}}! Consider freeing up space.`);
    }
    return false
  }

  if (key === LCName.Notification) {
    window.dispatchEvent(new Event("storageNotif"));
  }

  if (key === LCName.isHaveNotif) {
    window.dispatchEvent(new Event("storageHaveNotif"));
  }

  if (key === LCName.Failover) {
    window.dispatchEvent(new Event("storageFailover"));
  }
  return true
}

const f = () => {
  let eventTarget = new EventTarget();
  // eventTarget.dispatchEvent()

  let event = new Event("storage");
  // event.target.key =
};

export function removeSession() {
  //  localStorage.clear();
  removeLC(LCName.User);
  removeLC(LCName.SessionID);
  removeLC(LCName.SessionCreated);
  removeLC(LCName.SessionToken);
  removeLC(LCName.CompanyID);
  removeLC(LCName.SelectedChat);
  removeLC("CID");
  removeLC("UID");
  removeLC(LCName.CompanyList);
  removeLC(LCName.Client);
  removeLC(LCName.UserData);
  removeLC(LCName.ChannelList);
  removeLC(LCName.Account);
  removeLC(LCName.AccountList);
  removeLC(LCName.SyncRes);
  //  removeNotification()
}

export function removeNotification() {
  removeLC(LCName.Notification);
  removeLC(LCName.isHaveNotif);
  removeLC(LCName.NotificationToken);
  window.dispatchEvent(new Event("storageNotif"));
}
