import { FC, useState } from "react";
import { Modal, Offcanvas } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {
  KTCard,
  KTSVG,
  toAbsoluteUrl,
} from "../../../../../../../resources/helpers";
import { ModalConnectProp } from "./MarketplaceTargetModalSelect";
import ModalAuthGuide from "./MarketplaceModalAuthGuide";
import { useMediaQuery } from "react-responsive";

const BASE_URL = `${process.env.REACT_APP_SERVER_URL}`;
const TargetModalConnect: FC<{
  isOpen?: boolean;
  onClose?: () => void;
  onAction?: any;
  marketplaceData?: ModalConnectProp;
}> = ({ isOpen, onClose, marketplaceData, onAction }) => {
  const { t } = useTranslation();
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const [isHowToAuthOpen, setIsHowToAuthOpen] = useState(false);
  const isHowToAuthClose = () => {
    setIsHowToAuthOpen(false);
    onAction();
  };

  console.log(marketplaceData, "mardata pkl");
  const marketName = marketplaceData?.name;
  const marketplaceURL = toAbsoluteUrl(
    `/media/logos/marketplace/${marketName?.toLowerCase()}.png`
  );

  const startConnectButtonHandler = async () => {
    setIsHowToAuthOpen(true);
    const callbackURL = marketplaceData?.callbackURL;
    if (callbackURL) {
      window.open(callbackURL, "_blank");
    }
  };

  return (
    <>
      {isOpen && !isHowToAuthOpen && (
        <>
          {isTabletOrMobile ? (
            <Offcanvas
              show={isOpen}
              onHide={() => onClose && onClose()}
              placement="bottom"
              style={{
                borderTopLeftRadius: "30px",
                borderTopRightRadius: "30px",
              }}
              className="h-auto"
            >
              <Offcanvas.Header closeButton className="border-bottom ms-5 me-5">
                <Offcanvas.Title className="fs-2 pt-2">
                  {t("Marketplace.Modal.Header.addMarketplace") + marketName}
                </Offcanvas.Title>
              </Offcanvas.Header>
              <Offcanvas.Body className="h-auto">
                <KTCard>
                  <div className="d-flex flex-column align-items-center pt-5 pb-5">
                    <div className="pb-5 d-flex flex-row align-items-center justify-content-center">
                      <img
                        className="h-70px w-70px"
                        src={marketplaceURL}
                        alt={marketName}
                      />
                      <i
                        className="bi bi-arrow-left-right bi-lg px-10 py-10"
                        style={{ fontSize: "2rem" }}
                      ></i>
                      <img
                        className="h-20px"
                        src={toAbsoluteUrl(
                          "/media/logos/icon-header-wb-sm.png"
                        )}
                        alt={marketName}
                      />
                    </div>
                    <div className=" justify-content-between px-5 fs-4 pb-10 pt-5">
                      <p>
                        {t("Marketplace.Modal.Message.Connect.Front") +
                          marketName?.replace("_", " ") +
                          t("Marketplace.Modal.Message.Connect.End")}
                      </p>
                    </div>
                    <div className="d-flex w-100 pt-10">
                      <button
                        disabled={
                          marketplaceData?.callbackURL === undefined
                            ? true
                            : false
                        }
                        className="btn btn-primary w-100 fs-2"
                        onClick={startConnectButtonHandler}
                      >
                        {t("Marketplace.Modal.Header.addMarketplace.Button")}
                      </button>
                    </div>
                  </div>
                </KTCard>
              </Offcanvas.Body>
            </Offcanvas>
          ) : (
            <>
              <Modal
                animation
                centered
                show={isOpen}
                onHide={() => onClose && onClose()}
                className="md"
                scrollable={false}
              >
                <Modal.Header closeButton className="bg-light">
                  <Modal.Title data-testid="modal-title">
                    {t("Marketplace.Modal.Header.addMarketplace") + marketName}
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body className="bg-light">
                  <KTCard className="align-items-center pt-10 pb-10">
                    <div className="pb-5">
                      <img
                        className="h-80px w-80px"
                        src={marketplaceURL}
                        alt={marketName}
                      />
                      <i
                        className="bi bi-arrow-left-right bi-lg px-10 py-10"
                        style={{ fontSize: "2rem" }}
                      ></i>
                      <img
                        className="h-20px"
                        src={toAbsoluteUrl(
                          "/media/logos/icon-header-wb-sm.png"
                        )}
                        alt={marketName}
                      />
                    </div>
                    <div className="align-items-center px-5">
                      <p>
                        {t("Marketplace.Modal.Message.Connect.Front") +
                          marketName?.replace("_", " ") +
                          t("Marketplace.Modal.Message.Connect.End")}
                      </p>
                    </div>
                  </KTCard>
                </Modal.Body>
                <Modal.Footer>
                  <button
                    disabled={
                      marketplaceData?.callbackURL === undefined ? true : false
                    }
                    className="btn btn-primary"
                    onClick={startConnectButtonHandler}
                  >
                    {t("Marketplace.Modal.Header.addMarketplace.Button")}
                  </button>
                </Modal.Footer>
              </Modal>
            </>
          )}
        </>
      )}

      {isHowToAuthOpen && (
        <ModalAuthGuide
          isOpen={isHowToAuthOpen}
          onClose={isHowToAuthClose}
          marketplaceName={marketName}
        />
        // <Modal
        //   animation
        //   centered
        //   show={isHowToAuthOpen}
        //   onHide={() => isHowToAuthClose && isHowToAuthClose()}
        //   className="md"
        //   scrollable={false}
        // >
        //   <Modal.Header closeButton className="bg-light">
        //     <Modal.Title data-testid="modal-title">
        //     {t("Marketplace.Modal.HowToAuth.Title")}
        //     </Modal.Title>
        //   </Modal.Header>
        //   <Modal.Body className="bg-light">
        //     <KTCard className="bg-white pt-10 pb-10 ps-5 pe-5">
        //       <div>
        //         <h5 className="ps-4 mb-5">{t("Marketplace.Modal.HowToAuth.Body.Follow")}</h5>
        //         <ol>
        //           <li>{t("Marketplace.Modal.HowToAuth.Body.One.One")}{marketName}{t("Marketplace.Modal.HowToAuth.Body.One.Two")}</li>
        //           <li>{t("Marketplace.Modal.HowToAuth.Body.Two")}</li>
        //           <li>{t("Marketplace.Modal.HowToAuth.Body.Three")}{marketName}</li>
        //           <li>{t("Marketplace.Modal.HowToAuth.Body.Four")}</li>
        //         </ol>
        //       </div>
        //     </KTCard>
        //   </Modal.Body>
        //   <Modal.Footer className="bg-white">
        //     <button
        //       type="button"
        //       className="btn btn-primary w-25 text-center"
        //       onClick={() => isHowToAuthClose && isHowToAuthClose()}
        //     >
        //       {t("Marketplace.Modal.HowToAuth.Footer.Button")}
        //     </button>
        //   </Modal.Footer>
        // </Modal>
      )}
    </>
  );
};

export default TargetModalConnect;
