import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { PageLink, PageTitle } from "../../core";
import { Order, OrderStatus7Days } from "src/app/models/Order";
import { format } from "date-fns";
import * as lc from "src/app/modules/localstorage/index";
import { Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import ShippingLimitLabel from "../shiporder-export/order-list/components/ShippingLimitLabel";
import db, { createNotificationAnotherUser, createRef } from "src/db";
import * as Chat from "src/actions/chat";
import { useDispatch } from "react-redux";
import * as chatSlice from "src/app/modules/chat/redux/ChatSlice";
import { deleteItemListCIQ } from "src/app/modules/ciq/redux/CIQSlice";
import { useNavigate } from "react-router-dom";
import {
  setIsModalOpen,
  setResponseExportOrder,
} from "src/app/components/modal/ModalExportOrder/redux/ModalExportOrderSlice";
import { useMediaQuery } from "react-responsive";
import { useDebounce } from "src/resources/helpers";
import { isSameStatusValue } from "../shiporder-export/OrderPage";
import * as userServ from "src/db/serviceUser";
import SyncModal from "../shiporder-export/order-list/components/modal/SyncModal";
import SyncResultModal from "../shiporder-export/order-list/components/modal/SyncResultModal";
import ListComponent from "../../../components/list/NewList";
import PriorityCell from "../shiporder-export/order-list/table/columns/PriorityCell";
import { OrderSNCell } from "../shiporder-export/order-list/table/columns/OrderSNCell";
import { Color } from "src/app/modules/notify";
import { useFirestoreTable } from "../../../components/list/utils/useFirestoreTable";
import { handleCIQ } from "../../ciq-management/ciq-list/core/_requests";

import ModalExportOrder from "src/app/components/modal/ModalExportOrder/ModalExportOrder";
import OrderModal from "../shiporder-export/order-list/modal/OrderModal";

type Columns<T> = {
  title: string;
  field: keyof T;
  value: (props: { value: any }) => JSX.Element | Promise<JSX.Element>;
};

const statusWithAction: string[] = [OrderStatus7Days.NewOrder];
const statusProcessing: string[] = [OrderStatus7Days.Processing];

const NewOrderPage = () => {
  const { t } = useTranslation();
  const [dataAccount, setDataAccount] = useState([] as any[]);
  const [filterAccount, setFilterAccount] = useState([] as any[]);
  const [filterShipment, setFilterShipment] = useState([] as any[]);
  const [listReady, setListReady] = useState<boolean>(false);
  const isMobileView = useMediaQuery({ query: "(max-width: 900px)" });
  const navigate = useNavigate();

  // Breadcrumb page
  const usersBreadcrumbs: Array<PageLink> = [
    {
      title: t("Menu.Order"),
      path: "#",
      isSeparator: false,
      isActive: false,
    },
    {
      title: "",
      path: "",
      isSeparator: true,
      isActive: false,
    },
  ];

  // kolom default tabel di order page
  const defaultColumns: Columns<Order>[] = [
    {
      field: "marketplaceType",
      title: t("Marketplace.Menu.Marketplace"),
      value: (props: any) => {
        const propsData = props?.row?.original;
        const accountId = propsData?.account?.id;
        let marketplaceName = dataAccount.find(
          (item: any) => item.id === accountId
        )?.name;

        return (
          <div className="w-100 m-0 p-0 d-flex flex-column justify-content-center flex-wrap">
            <p className="p-0 m-0 fw-bolder" style={{ color: "#999999" }}>
              {props?.value}
            </p>
            <p
              className="w-100 p-0 m-0 flex-wrap text-wrap"
              style={{
                wordBreak: "break-word",
                whiteSpace: "normal",
                overflowWrap: "break-word",
              }}
            >
              {marketplaceName || " "}
            </p>
          </div>
        );
      },
    },
    {
      field: "ordersn",
      title: t("OrderList.OrderNumber"),
      value: (props: any) => {
        const data = props.row.original;
        return (
          <div
            className={
              isMobileView
                ? "m-0 p-0 d-flex justify-content-end"
                : "m-0 p-0 h-auto d-flex align-items-center justify-content-start fw-bolder"
            }
          >
            <OrderSNCell {...props} orderData={data} />
          </div>
        );
      },
    },
    {
      field: "isPrepack",
      title: t("Value.Pack.ColumnHeader"),
      value: (props: any) => {
        const isPrepack = props.value;
        return (
          <>
            <div>
              <p className="p-0 m-0 fw-bolder" style={{ color: "#999999" }}>
                {props.value}
              </p>
              <p className="p-0 m-0">
                {isPrepack
                  ? t("Value.Pack.ColumnValue.isPrepack")
                  : t("Value.Pack.ColumnValue.nonPrepack")}
              </p>
            </div>
          </>
        );
      },
    },
    {
      field: "marketplaceOrderCreatedAt",
      title: t("OrderList.OrderTime"),
      value: (props: any) => {
        return (
          <>
            <p className="p-0 m-0 fw-bolder" style={{ color: "#999999" }}>
              {props?.value?.seconds
                ? format(new Date(props?.value?.seconds * 1000), "dd/MM/yyyy")
                : props?.value
                ? format(new Date(props?.value), "dd/MM/yyyy")
                : " - "}
            </p>
            <p className="p-0 m-0">
              {props?.value?.seconds
                ? format(new Date(props?.value?.seconds * 1000), "HH:mm")
                : props?.value
                ? format(new Date(props?.value), "HH:mm")
                : " - "}
            </p>
          </>
        );
      },
    },
    {
      field: "forwardError",
      title: t("OrderList.ForwardError"),
      value: (props: any) => {
        const data = props.row.original;
        const key = `${data.id}-forward-error-tooltip`;

        if (data.isForwardSuccess == null) {
          return (
            <div
              className={
                isMobileView
                  ? "m-0 p-0 d-flex justify-content-end fw-bolder"
                  : "m-0 p-0 d-flex justify-content-start fw-bolder"
              }
            >
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={(props) => (
                  <Tooltip {...props} id={key} className="fs-5">
                    {t("OrderList.ForwardError.OrderNotForwarded")}
                  </Tooltip>
                )}
              >
                <i className="bi bi-info-circle-fill text-warning fs-1"></i>
              </OverlayTrigger>
            </div>
          );
        }

        // If forwardError is false it means that the order has been forwarded successfully
        if (!data.forwardError && data.isForwardSuccess) {
          return (
            <div
              className={
                isMobileView
                  ? "m-0 p-0 d-flex justify-content-end fw-bolder"
                  : "m-0 p-0 d-flex justify-content-start fw-bolder"
              }
            >
              <i className="bi bi-check-circle-fill text-success fs-1"></i>
            </div>
          );
        }

        // If isForwardError is true it means that the order has not been forwarded
        return (
          <div
            className={
              isMobileView
                ? "m-0 p-0 d-flex justify-content-end fw-bolder"
                : "m-0 p-0 d-flex justify-content-start fw-bolder"
            }
          >
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={(props) => (
                <Tooltip {...props} id={key} className="fs-5">
                  {data.forwardError ??
                    t("OrderList.ForwardError.DefaultError")}
                </Tooltip>
              )}
            >
              <i className="bi bi-exclamation-circle-fill text-danger fs-1"></i>
            </OverlayTrigger>
          </div>
        );
      },
    },
    {
      field: "marketplaceLogisticChannelParent",
      title: t("OrderList.Shipper"),
      value: (props: any) => {
        const label: string = props.cell.row.original.shippingLabel;
        return (
          <div>
            <p className="p-0 m-0 fw-bolder" style={{ color: "#999999" }}>
              {props.value}
            </p>
            <p className="p-0 m-0">{label}</p>
          </div>
        );
      },
    },
  ];

  // kolom dengan kolom priority di order page
  const columnsWithPriority: Columns<Order>[] = [
    {
      field: "marketplaceType",
      title: t("Marketplace.Menu.Marketplace"),
      value: (props: any) => {
        const propsData = props?.row?.original;
        const accountId = propsData?.account?.id;
        let marketplaceName = dataAccount.find(
          (item: any) => item.id === accountId
        )?.name;

        return (
          <div className="w-100 m-0 p-0 d-flex flex-column justify-content-center flex-wrap">
            <p className="p-0 m-0 fw-bolder" style={{ color: "#999999" }}>
              {props?.value}
            </p>
            <p
              className="w-100 p-0 m-0 flex-wrap text-wrap"
              style={{
                wordBreak: "break-word",
                whiteSpace: "normal",
                overflowWrap: "break-word",
              }}
            >
              {marketplaceName || " "}
            </p>
          </div>
        );
      },
    },
    {
      field: "ordersn",
      title: t("OrderList.OrderNumber"),
      value: (props: any) => {
        const data = props.row.original;
        return (
          <>
            <div
              className={
                isMobileView
                  ? "m-0 p-0 d-flex justify-content-end fw-bolder"
                  : "m-0 p-0 fw-bolder"
              }
              style={{ color: "#999999" }}
            >
              <OrderSNCell {...props} orderData={data} />
            </div>
          </>
        );
      },
    },
    {
      field: "isPrepack",
      title: t("Value.Pack.ColumnHeader"),
      value: (props: any) => {
        const isPrepack = props.value;
        return (
          <>
            <div>
              <p className="p-0 m-0 fw-bolder" style={{ color: "#999999" }}>
                {props.value}
              </p>
              <p className="p-0 m-0">
                {isPrepack
                  ? t("Value.Pack.ColumnValue.isPrepack")
                  : t("Value.Pack.ColumnValue.nonPrepack")}
              </p>
            </div>
          </>
        );
      },
    },
    {
      field: "marketplaceOrderCreatedAt",
      title: t("OrderList.OrderTime"),
      value: (props: any) => {
        return (
          <>
            <p className="p-0 m-0 fw-bolder" style={{ color: "#999999" }}>
              {props?.value?.seconds
                ? format(new Date(props?.value?.seconds * 1000), "dd/MM/yyyy")
                : props?.value
                ? format(new Date(props?.value), "dd/MM/yyyy")
                : " - "}
            </p>
            <p className="p-0 m-0">
              {props?.value?.seconds
                ? format(new Date(props?.value?.seconds * 1000), "HH:mm")
                : props?.value
                ? format(new Date(props?.value), "HH:mm")
                : " - "}
            </p>
          </>
        );
      },
    },
    {
      field: "orderPriorityNumber",
      title: t("OrderList.PriorityNumber"),
      value: (props: any) => {
        return (
          <>
            <div
              className={
                isMobileView
                  ? "m-0 p-0 d-flex justify-content-end fw-bolder"
                  : "m-0 p-0 d-flex justify-content-start fw-bolder"
              }
            >
              <PriorityCell {...props} oldOrder={props.row.original} />
            </div>
          </>
        );
      },
    },
    {
      field: "forwardError",
      title: t("OrderList.ForwardError"),
      value: (props: any) => {
        const data = props.row.original;
        const key = `${data.id}-forward-error-tooltip`;

        if (data.isForwardSuccess == null) {
          return (
            <div
              className={
                isMobileView
                  ? "m-0 p-0 d-flex justify-content-end fw-bolder"
                  : "m-0 p-0 d-flex justify-content-start fw-bolder"
              }
            >
              <OverlayTrigger
                placement="top"
                delay={{ show: 250, hide: 400 }}
                overlay={(props) => (
                  <Tooltip {...props} id={key} className="fs-5">
                    {t("OrderList.ForwardError.OrderNotForwarded")}
                  </Tooltip>
                )}
              >
                <i className="bi bi-info-circle-fill text-warning fs-1"></i>
              </OverlayTrigger>
            </div>
          );
        }

        // If forwardError is false it means that the order has been forwarded successfully
        if (!data.forwardError && data.isForwardSuccess) {
          return (
            <div
              className={
                isMobileView
                  ? "m-0 p-0 d-flex justify-content-end fw-bolder"
                  : "m-0 p-0 d-flex justify-content-start fw-bolder"
              }
            >
              <i className="bi bi-check-circle-fill text-success fs-1"></i>
            </div>
          );
        }

        // If isForwardError is true it means that the order has not been forwarded
        return (
          <div
            className={
              isMobileView
                ? "m-0 p-0 d-flex justify-content-end fw-bolder"
                : "m-0 p-0 d-flex justify-content-start fw-bolder"
            }
          >
            <OverlayTrigger
              placement="top"
              delay={{ show: 250, hide: 400 }}
              overlay={(props) => (
                <Tooltip {...props} id={key} className="fs-5">
                  {data.forwardError ??
                    t("OrderList.ForwardError.DefaultError")}
                </Tooltip>
              )}
            >
              <i className="bi bi-exclamation-circle-fill text-danger fs-1"></i>
            </OverlayTrigger>
          </div>
        );
      },
    },
    {
      field: "shipByDate",
      title: t("OrderList.ShippingLimit"),
      value: (props: any) => {
        return (
          <div
            className={
              isMobileView
                ? "m-0 p-0 d-flex justify-content-end fw-bolder"
                : "m-0 p-0 d-flex justify-content-start fw-bolder"
            }
          >
            <ShippingLimitLabel shippingTimestamp={props.value} />
          </div>
        );
      },
    },
    {
      field: "marketplaceLogisticChannelParent",
      title: t("OrderList.Shipper"),
      value: (props: any) => {
        const label: string = props.cell.row.original.shippingLabel;
        return (
          <>
            <div>
              <p className="p-0 m-0 fw-bolder" style={{ color: "#999999" }}>
                {props.value}
              </p>
              <p className="p-0 m-0">{label}</p>
            </div>
          </>
        );
      },
    },
  ];

  // useeffect digunakan untuk data filter by account dan by shipment di order page
  useEffect(() => {
    const getDataAccount = async () => {
      try {
        let tempData: any = [];
        let tempDataFilter: any = [];
        let query = db.collection("account").where("type", "!=", "whatsapp");

        let snapshot = await query
          .orderBy("type", "asc")
          .orderBy("name", "asc")
          .get();
        snapshot.forEach((doc) => {
          let obj = doc.data();
          obj["id"] = doc.id;
          tempData.push(obj);
          tempDataFilter.push({
            label: `${doc.data().type} - ${doc.data().name}`,
            value: `id_${doc.id}`,
          });
        });
        setFilterAccount(tempDataFilter);
        setDataAccount(tempData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const getShipper = async () => {
      try {
        let shipperFilter = [] as any;
        let shipment = await db
          .collection("shipments")
          .where("isParent", "==", true)
          .orderBy("name", "asc")
          .get();

        shipment.forEach((doc) => {
          shipperFilter.push({
            label: doc.data().name,
            value: doc.data().name,
          });
        });
        setFilterShipment(shipperFilter);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    getDataAccount();
    getShipper();
  }, []);

  // useeffect sebagai trigger ketika data filter account dan shipment sudah didapatkan
  useEffect(() => {
    if (filterAccount.length > 0 && filterShipment.length > 0) {
      setListReady(true);
    }
  }, [filterAccount, filterShipment]);

  const dispatch = useDispatch();
  const {
    refreshData,
    selectedItems,
    TableState,
    updateTableState,
    setReadyToShipOrder,
    setExportedOrder,
    responseExportOrder,
  } = useFirestoreTable();

  const activeOrderStatus = (
    TableState.filters?.find(
      (filter: any) => filter?.field === "exportStatus"
    ) as any
  )?.value;

  const isOrderStatusNew = isSameStatusValue(
    activeOrderStatus,
    statusWithAction
  );
  const isOrderStatusProcessing = isSameStatusValue(
    activeOrderStatus,
    statusProcessing
  );

  const [modalOpen, setModalOpen] = useState(false);
  const modalClose = () => setModalOpen(false);

  const [modalSyncresOpen, setModalSyncresOpen] = useState(false);
  const modalSyncresClose = () => {
    setModalSyncresOpen(false);
    lc.removeLC("isSync");
    lc.removeLC("isSyncInProgress");
    lc.removeLC("syncDate");
  };
  const [syncResult, setSyncResult] = useState<any>();
  const [isSync, setIsSync] = useState<boolean>(
    lc.getItemLC("isSync") === "false" || false
    // false
  );
  const [isLoadingSync, setIsLoadingSync] = useState(false);

  const syncing = (state: boolean) => {
    setIsSync(state);
    lc.setItemLC("isSync", JSON.stringify(state));
    setIsLoadingSync(state);
    const storedSyncDate = lc.getItemLC("syncDate");
    if (storedSyncDate) {
      try {
        const parsedSyncDate = storedSyncDate as Array<string>;
        const reformattedSyncDate = parsedSyncDate?.map((date: string) => {
          return date.replace(/\//g, "/");
        });
        console.log(
          "Data from SyncDate state di useeffect:",
          reformattedSyncDate
        );
        // Set syncDate to the retrieved value
        setSyncDate(reformattedSyncDate);
      } catch (error) {
        console.error("Error parsing or processing storedSyncDate:", error);
      }
    }
  };

  const [syncDate, setSyncDate] = useState<Array<string>>([]);

  const openSyncModal = (syncRes: any, syncDate: Array<string>) => {
    setSyncResult(syncRes);
    const order: Array<any> = [];
    var totalOrdersAffc: number = 0;
    syncRes?.response?.map((resp: any) => {
      const objData = {
        account: createRef("account", resp.account),
        error: resp.error,
        totalSyncOrder: resp.totalNewOrder,
        totalUpdatedOrder: resp.totalUpdatedOrder,
      };
      order.push(objData);
      totalOrdersAffc += resp.totalNewOrder + resp.totalUpdatedOrder;
    });

    const toSendToNotif = {
      startTime: syncDate[0],
      endTime: syncDate[1],
      orders: order,
    };

    const reformattedProp: Array<string> = syncDate.map((date: string) => {
      return date.replace(/\//g, "/");
    });
    console.log(
      "Data to be set to SyncDate State from SyncModal:",
      reformattedProp
    );
    setSyncDate(reformattedProp);

    userServ.insertSyncResultNotification(
      lc.getItemLC(lc.LCName.UserID),
      toSendToNotif
    );

    lc.setItemLC("syncResult", JSON.stringify(syncRes));
  };

  useEffect(() => {
    // Retrieve syncDate from local storage
    const storedSyncDate = lc.getItemLC("syncDate");
    if (storedSyncDate) {
      try {
        const parsedSyncDate = storedSyncDate as Array<string>;
        const reformattedSyncDate = parsedSyncDate?.map((date: string) => {
          return date.replace(/\//g, "/");
        });
        console.log(
          "Data from SyncDate state di useeffect:",
          reformattedSyncDate
        );
        // Set syncDate to the retrieved value
        setSyncDate(reformattedSyncDate);
      } catch (error) {
        console.error("Error parsing or processing storedSyncDate:", error);
      }
    }

    // Retrieve syncResult from local storage
    const storedSyncResult = lc.getItemLC("syncResult");
    if (storedSyncResult) {
      const parsedSyncResult = JSON.parse(storedSyncResult);
      console.log("Data from SyncResult state:", parsedSyncResult);
      // Set syncResult to the retrieved value
      setSyncResult(parsedSyncResult);
      setIsLoadingSync(false);
    }

    // Check if sync is in progress and open the modal
    const isSyncInProgress = lc.getItemLC("isSyncInProgress") === "true";
    if (isSyncInProgress) {
      setModalSyncresOpen(true);
    }
  }, []);

  const buttonExportOrderHandler = async (selectedItems: any) => {
    dispatch(setIsModalOpen(true));
    setExportedOrder(selectedItems);
  };

  useEffect(() => {
    if (responseExportOrder) {
      console.log("responseExportOrder", { responseExportOrder });
      dispatch(setResponseExportOrder(responseExportOrder));
    }
  }, [responseExportOrder, dispatch]);

  const fiveDaysAgo = new Date(Date.now() - 5 * 24 * 60 * 60 * 1000);

  return (
    <>
      <PageTitle breadcrumbs={usersBreadcrumbs}>
        {t("OrderList.Title")}
      </PageTitle>
      {listReady && (
        <ListComponent<Order>
          collection="orders"
          collectionGroup={false}
          tabs={[
            // All
            {
              label: t("OrderStatus7Days.All"),
              filter: [],
              sort: [
                {
                  field: "marketplaceOrderCreatedAt",
                  label: t("Common.Column.CreatedAt"),
                  direction: "asc",
                },
              ],
            },
            // Unpaid
            {
              label: t("OrderStatus7Days.Unpaid"),
              filter: [
                {
                  field: "status",
                  type: "text",
                  value: OrderStatus7Days.Unpaid,
                  label: t("OrderStatus7Days.Unpaid"),
                },
                {
                  field: "marketplaceOrderCreatedAt",
                  type: "date-range",
                  value: {
                    startDate: fiveDaysAgo,
                    endDate: new Date(),
                  },
                  label: "5 days ago",
                },
              ],
              sort: [
                {
                  field: "marketplaceOrderCreatedAt",
                  label: t("Common.Column.CreatedAt"),
                  direction: "asc",
                },
                {
                  field: "orderPriorityNumber",
                  label: t("OrderList.PriorityNumber"),
                  direction: "desc",
                },
              ],
            },
            // New Order
            {
              label: t("OrderStatus7Days.NewOrder"),
              filter: [
                // {
                //   label: "isPrepack",
                //   field: "isPrepack",
                //   type: "boolean",
                //   value: false,
                // },
                {
                  field: "status",
                  type: "text",
                  value: OrderStatus7Days.NewOrder,
                  label: t("OrderStatus7Days.NewOrder"),
                },
                {
                  field: "marketplaceOrderCreatedAt",
                  type: "date-range",
                  value: {
                    startDate: fiveDaysAgo,
                    endDate: new Date(),
                  },
                  label: "5 days ago",
                },
              ],
              sort: [
                {
                  field: "marketplaceOrderCreatedAt",
                  label: t("Common.Column.CreatedAt"),
                  direction: "asc",
                },
                {
                  field: "orderPriorityNumber",
                  label: t("OrderList.PriorityNumber"),
                  direction: "desc",
                },
              ],
              customColumns: columnsWithPriority,
              customSelection: {
                headerSelection: true,
                rowSelection: true,
              },
              customAction: {
                headerTitle: t("Marketplace.Table.Header.Action"),
                value: (props: any) => {
                  const marketplace = props.row.original.marketplaceType;
                  const orderID: string = props.row.original.id;
                  function arrangeShipment() {
                    props.setReadyToShipOrder([orderID]);
                  }
                  async function buttonInitiateChatHandler() {
                    if (
                      marketplace === "tokopedia" ||
                      marketplace === "lazada"
                    ) {
                      const initiatedChat =
                        await Chat.createMessageInitiateChatMarketplace(
                          marketplace,
                          orderID
                        );
                      if (initiatedChat) {
                        const collaborationId = initiatedChat.collaborationId;
                        const roomId = initiatedChat.roomId;
                        const collab = await Chat.fetchCollaborationByID(
                          collaborationId
                        );

                        if (!collab) {
                          console.error("Error fetching collaboration data");
                          return;
                        }
                        dispatch(chatSlice.updateChatList(collab));
                        dispatch(chatSlice.setSelectedChat(collab.id));
                        dispatch(chatSlice.setSelectedRoom(roomId));
                        dispatch(deleteItemListCIQ(roomId));
                        navigate(`/handled-customer`);
                        dispatch(
                          chatSlice.setOrderInitiateMessage(props.row.original)
                        );
                        if (
                          collab.company &&
                          collab.company?.id &&
                          collab.customer
                        ) {
                          createNotificationAnotherUser(collab);
                        } else {
                          dispatch(chatSlice.updateAllChatChatList(collab));
                        }
                      }
                    } else if (marketplace === "shopee") {
                      //shopee
                      let userSearchKey = props.row.original.buyerId.toString();
                      let collab =
                        (await Chat.fetchCollaborationByUserSearchKeys(
                          "shopee",
                          userSearchKey
                        )) as any;

                      if (!collab) {
                        console.log("Error fetching collaboration data");
                        const initiatedChat =
                          await Chat.createMessageInitiateChatMarketplace(
                            marketplace,
                            orderID
                          );
                        if (initiatedChat) {
                          const collaborationId = initiatedChat.collaborationId;
                          const roomId = initiatedChat.roomId;
                          collab = await Chat.fetchCollaborationByID(
                            collaborationId
                          );
                        }
                      }

                      const collabId = collab?.id.toString() ?? "";
                      const room = collab?.roomsModel?.filter(
                        (room: any) => room?.userSearchKey === userSearchKey
                      )[0];
                      const roomId = room?.id;
                      if (room.status === "queue") {
                        const handleCIQResult = await handleCIQ(
                          collabId.toString(),
                          roomId
                        );
                      }
                      dispatch(chatSlice.updateChatList(collab));
                      dispatch(chatSlice.setSelectedChat(collabId.toString()));
                      dispatch(chatSlice.setSelectedRoom(roomId));
                      dispatch(deleteItemListCIQ(roomId));
                      navigate(`/handled-customer`);
                      dispatch(
                        chatSlice.setOrderInitiateMessage(props.row.original)
                      );
                      if (
                        collab.company &&
                        collab.company?.id &&
                        collab.customer
                      ) {
                        createNotificationAnotherUser(collab);
                      } else {
                        dispatch(chatSlice.updateAllChatChatList(collab));
                      }
                    }
                  }

                  return !isMobileView ? ( // Desktop view
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="secondary"
                        id="dropdown-basic"
                        data-testid="dropdownToggleAction"
                        className="px-2 py-1 fs-7 my-4"
                      >
                        {t("Marketplace.Table.Header.Action")}
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="bg-light text-center">
                        <div>
                          <div className="dropdown-item btn btn-active-light btn-sm">
                            <button
                              type="button"
                              className="btn btn-sm"
                              onClick={() => arrangeShipment()}
                            >
                              {t("OrderList.Button.ArrangeShipment")}
                            </button>
                          </div>
                          <div className="dropdown-item btn btn-active-light btn-sm">
                            {marketplace === "tokopedia" ||
                            marketplace === "lazada" ||
                            marketplace === "shopee" ? (
                              <button
                                type="button"
                                className="btn btn-sm"
                                onClick={() => buttonInitiateChatHandler()}
                              >
                                {t("OrderList.Button.GoToChat")}
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="btn btn-sm text-secondary"
                                aria-disabled
                              >
                                {t("OrderList.Button.GoToChat")}
                              </button>
                            )}
                          </div>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : (
                    // Mobile view
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="transparent"
                        className="no-caret w-auto m-0 p-0"
                        bsPrefix="bi bi-three-dots-vertical text-primary fw-bolder fs-1 p-0 m-0"
                      ></Dropdown.Toggle>
                      <Dropdown.Menu>
                        <div>
                          <div className="dropdown-item btn btn-active-light btn-sm">
                            <button
                              type="button"
                              className="btn btn-sm"
                              onClick={() => arrangeShipment()}
                            >
                              {t("OrderList.Button.ArrangeShipment")}
                            </button>
                          </div>
                          <div className="dropdown-item btn btn-active-light btn-sm">
                            {marketplace === "tokopedia" ||
                            marketplace === "lazada" ? (
                              <button
                                type="button"
                                className="btn btn-sm"
                                onClick={() => buttonInitiateChatHandler()}
                              >
                                {t("OrderList.Button.GoToChat")}
                              </button>
                            ) : (
                              <button
                                type="button"
                                className="btn btn-sm text-secondary"
                                aria-disabled
                              >
                                {t("OrderList.Button.GoToChat")}
                              </button>
                            )}
                          </div>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  );
                },
              },
              customExtra: [
                {
                  label: t("OrderList.Button.ArrangeShipment"),
                  value: (props: any) => {
                    return (
                      <button
                        type="button"
                        className="btn btn-primary w-100 m-0"
                        disabled={props.selectedItems.length <= 0}
                        onClick={() => {
                          props.setReadyToShipOrder(props.selectedItems);
                        }}
                      >
                        {t("OrderList.Button.ArrangeShipment")}
                      </button>
                    );
                  },
                },
                {
                  label: t("OrderList.Button.Sync"),
                  value(props) {
                    return !isSync ? (
                      <button
                        type="button"
                        className="btn btn-light-primary w-100"
                        onClick={() => setModalOpen(true)}
                      >
                        {t("OrderList.Button.Sync")}
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-light-primary w-100"
                        disabled={true}
                      >
                        <span className="spinner-border spinner-border-sm"></span>
                        {t("OrderList.Sync.IsSync")}
                      </button>
                    );
                  },
                },
              ],
            },
            // Processing
            {
              label: t("OrderStatus7Days.Processing"),
              filter: [
                // {
                //   label: "isPrepack",
                //   field: "isPrepack",
                //   type: "boolean",
                //   value: false,
                // },
                {
                  field: "exportStatus",
                  type: "text",
                  value: OrderStatus7Days.Processing,
                  label: t("OrderStatus7Days.Processing"),
                },
                {
                  field: "marketplaceOrderCreatedAt",
                  type: "date-range",
                  value: {
                    startDate: fiveDaysAgo,
                    endDate: new Date(),
                  },
                  label: "5 days ago",
                },
              ],
              sort: [
                {
                  field: "marketplaceOrderCreatedAt",
                  label: t("Common.Column.CreatedAt"),
                  direction: "asc",
                },
                {
                  field: "orderPriorityNumber",
                  label: t("OrderList.PriorityNumber"),
                  direction: "desc",
                },
              ],
              customColumns: columnsWithPriority,
              customSelection: {
                headerSelection: true,
                rowSelection: true,
              },
              customAction: {
                headerTitle: t("Marketplace.Table.Header.Action"),
                value: (props: any) => {
                  async function exportOrderHandler() {
                    dispatch(setIsModalOpen(true));
                    props.setExportedOrder([props.row.original.id]);
                  }

                  return !isMobileView ? (
                    <div className="text-center">
                      <Dropdown>
                        <Dropdown.Toggle
                          variant="secondary"
                          id="dropdown-basic"
                          data-testid="dropdownToggleAction"
                          className="px-2 py-1 fs-7 my-4"
                        >
                          {t("Marketplace.Table.Header.Action")}
                        </Dropdown.Toggle>
                        <Dropdown.Menu className="bg-light text-center">
                          <div>
                            <div className="dropdown-item btn btn-active-light btn-sm">
                              <button
                                type="button"
                                className="btn btn-sm"
                                onClick={() => exportOrderHandler()}
                              >
                                {t("OrderList.Button.ExportOrder")}
                              </button>
                            </div>
                          </div>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  ) : (
                    <Dropdown>
                      <Dropdown.Toggle
                        variant="none"
                        className="no-caret w-auto m-0 p-0"
                        bsPrefix="bi bi-three-dots-vertical fs-1 pe-3"
                      ></Dropdown.Toggle>
                      <Dropdown.Menu>
                        <div className="dropdown-item btn btn-active-light btn-sm">
                          <button
                            type="button"
                            className="btn btn-sm"
                            onClick={() => exportOrderHandler()}
                          >
                            {t("OrderList.Button.ExportOrder")}
                          </button>
                        </div>
                      </Dropdown.Menu>
                    </Dropdown>
                  );
                },
              },
              customExtra: [
                {
                  label: t("OrderList.Button.ExportOrder"),
                  value(props: any) {
                    return (
                      <button
                        type="button"
                        className="btn btn-primary w-100 m-0"
                        disabled={props.selectedItems.length <= 0}
                        onClick={() => {
                          props.selectedItems.length > 0 &&
                            buttonExportOrderHandler(props.selectedItems);
                        }}
                      >
                        {t("OrderList.Button.ExportOrder")}
                      </button>
                    );
                  },
                },
                {
                  label: t("OrderList.Button.Sync"),
                  value(props) {
                    return !isSync ? (
                      <button
                        type="button"
                        className="btn btn-light-primary w-100"
                        onClick={() => setModalOpen(true)}
                      >
                        {t("OrderList.Button.Sync")}
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-light-primary w-100"
                        disabled={true}
                      >
                        <span className="spinner-border spinner-border-sm"></span>
                        {t("OrderList.Sync.IsSync")}
                      </button>
                    );
                  },
                },
              ],
            },
            // Ready to Ship
            {
              label: t("OrderStatus7Days.ReadyToShip"),
              filter: [
                // {
                //   label: "isPrepack",
                //   field: "isPrepack",
                //   type: "boolean",
                //   value: false,
                // },
                {
                  field: "exportStatus",
                  type: "text",
                  value: OrderStatus7Days.ReadyToShip,
                  label: t("OrderStatus7Days.ReadyToShip"),
                },
                {
                  field: "marketplaceOrderCreatedAt",
                  type: "date-range",
                  value: {
                    startDate: fiveDaysAgo,
                    endDate: new Date(),
                  },
                  label: "5 days ago",
                },
              ],
              sort: [
                {
                  field: "marketplaceOrderCreatedAt",
                  label: t("Common.Column.CreatedAt"),
                  direction: "asc",
                },
                {
                  field: "orderPriorityNumber",
                  label: t("OrderList.PriorityNumber"),
                  direction: "desc",
                },
              ],
              customColumns: [
                ...defaultColumns,
                {
                  field: "shipByDate",
                  title: t("OrderList.ShippingLimit"),
                  value: (props: any) => {
                    return (
                      <div
                        className={
                          isMobileView
                            ? "m-0 p-0 d-flex justify-content-end"
                            : "m-0 p-0 fw-bolder"
                        }
                      >
                        <ShippingLimitLabel shippingTimestamp={props.value} />
                      </div>
                    );
                  },
                },
              ],
            },
            // Shipping
            {
              label: t("OrderStatus7Days.Shipping"),
              filter: [
                {
                  field: "exportStatus",
                  type: "text",
                  value: OrderStatus7Days.Shipping,
                  label: t("OrderStatus7Days.Shipping"),
                },
                {
                  field: "marketplaceOrderCreatedAt",
                  type: "date-range",
                  value: {
                    startDate: fiveDaysAgo,
                    endDate: new Date(),
                  },
                  label: "5 days ago",
                },
              ],
              sort: [
                {
                  field: "marketplaceOrderCreatedAt",
                  label: t("Common.Column.CreatedAt"),
                  direction: "desc",
                },
              ],
            },
            // Completed
            {
              label: t("OrderStatus7Days.Completed"),
              filter: [
                {
                  field: "status",
                  type: "text",
                  value: OrderStatus7Days.Completed,
                  label: t("OrderStatus7Days.Completed"),
                },
              ],
              sort: [
                {
                  field: "marketplaceOrderCreatedAt",
                  label: t("Common.Column.CreatedAt"),
                  direction: "desc",
                },
              ],
            },
            // Cancelled
            {
              label: t("OrderStatus7Days.Cancelled"),
              filter: [
                {
                  field: "status",
                  type: "text",
                  value: OrderStatus7Days.Cancelled,
                  label: t("OrderStatus7Days.Cancelled"),
                },
              ],
              sort: [
                {
                  field: "marketplaceOrderCreatedAt",
                  label: t("Common.Column.CreatedAt"),
                  direction: "desc",
                },
              ],
            },
            // Return
            {
              label: t("OrderStatus7Days.Return"),
              filter: [
                {
                  field: "status",
                  type: "text",
                  value: OrderStatus7Days.Return,
                  label: t("OrderStatus7Days.Return"),
                },
              ],
              sort: [
                {
                  field: "marketplaceOrderCreatedAt",
                  label: t("Common.Column.CreatedAt"),
                  direction: "desc",
                },
              ],
            },
          ]}
          columns={defaultColumns}
          selection={{
            headerSelection: false,
            rowSelection: false,
          }}
          defaultFilters={[]}
          defaultSorting={[
            {
              field: "marketplaceOrderCreatedAt",
              label: t("Common.Column.CreatedAt"),
              direction: "asc",
            },
          ]}
          useFilter={[
            {
              field: "account",
              type: "option",
              label: `${t("OrderList.Sync.Account")} Marketplace`,
              options: [
                {
                  label: `${t("Common.Input.Select")}${t(
                    "OrderList.Sync.Account"
                  )}`,
                  value: "option-title",
                },
                ...filterAccount.sort((a, b) =>
                  a.label.localeCompare(b.label, undefined, {
                    sensitivity: "case",
                  })
                ),
              ] as any,
              value: "option-title",
            },
            {
              field: "marketplaceLogisticChannelParent",
              type: "option",
              label: t("OrderList.Shipper"),
              options: [
                {
                  label: t("Common.Placeholder.SelectShipper"),
                  value: "option-title",
                },
                ...filterShipment.sort((a, b) =>
                  a.label.localeCompare(b.label, undefined, {
                    sensitivity: "case",
                  })
                ),
              ],
              value: "option-title",
            },
            {
              field: "shippingLabel",
              type: "option",
              label: t("OrderList.Shipper.Mobile"),
              options: [
                {
                  label: t("Common.Placeholder.SelectLabelShipper"),
                  value: "option-title",
                },
                { label: "ECO", value: "ECO" },
                { label: "STD", value: "STD" },
                { label: "Economy", value: "Economy" },
                { label: "Regular", value: "Regular" },
                { label: "Standard", value: "Standard" },
                { label: "HALU", value: "HALU" },
                { label: "INSTANT", value: "INSTANT" },
                { label: "COL", value: "COL" },
                { label: "JTR", value: "JTR" },
                { label: "CARGO", value: "CARGO" },
                { label: "STANDARD", value: "STANDARD" },
                { label: "SAMEDAY", value: "SAMEDAY" },
                { label: "YES", value: "YES" },
                { label: "Instant", value: "Instant" },
                { label: "GOKIL", value: "GOKIL" },
                { label: "ECONOMY", value: "ECONOMY" },
              ],
              value: "option-title",
            },
          ]}
          searching={{
            field: "ordersn",
            useFirestore: true,
            value: "",
            caseSensitive: false,
          }}
          extra={[
            {
              label: t("OrderList.Button.Sync"),
              value(props) {
                return !isSync ? (
                  <button
                    type="button"
                    className="btn btn-light-primary w-100"
                    onClick={() => setModalOpen(true)}
                  >
                    {t("OrderList.Button.Sync")}
                  </button>
                ) : (
                  <button
                    type="button"
                    className="btn btn-light-primary w-100"
                    disabled={true}
                  >
                    <span className="spinner-border spinner-border-sm"></span>
                    {t("OrderList.Sync.IsSync")}
                  </button>
                );
              },
            },
          ]}
        >
          <OrderModal />
          <ModalExportOrder />
        </ListComponent>
      )}

      {/* modal */}
      {modalOpen && (
        <SyncModal
          isOpen={modalOpen} //buka SyncModal
          onClose={modalClose} //Tutup SyncModal
          onSync={syncing} //Proses Sync
          syncResult={openSyncModal} //Data Response
          onResultFinished={() => setModalSyncresOpen(true)}
          // syncDate={setSyncDateTime} //Data DateTime
        />
      )}

      {modalSyncresOpen && (
        <SyncResultModal
          isResultOpen={modalSyncresOpen} //Buka SyncResultModal
          isResultClose={modalSyncresClose} //Tutup SyncResultModal
          syncRes={syncResult} //Data Response
          syncDate={syncDate} //Data DateTime
        />
      )}
    </>
  );
};

export default NewOrderPage;
