import axios from "axios";
import * as bcrypt from "bcryptjs";
import db, { createRef } from "../../db";
import * as ApiSettingService from "../../db/serviceApiSetting";
import Account from "../../app/models/Account";

export interface IResponseFormat {
  message: string;
  url: string;
}

export const updateMarketplaceInformation = async (
  accountId: string,
  data: Partial<Account>
) => {
  const url: string = process.env.REACT_APP_SERVER_URL! + `/marketplace/update`;
  console.log(url, "ini url edid");

  const datas = {
    accountId: accountId,
    data: data,
  };

  try {
    // get latest access token from database
    const accountAccess = await ApiSettingService.getAccess();
    if (!accountAccess) {
      console.error("Failed To Get Access Token");
      return undefined;
    }
    const token = accountAccess?.accessToken;
    console.log(token, "ini token edid");

    const response = await axios.post(url, datas, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response, "ini response edid");

    console.log("edid Status from Server:", response.status);
    return response.data;
  } catch (error: any) {
    console.log("ERROR FROM SERVER!", error);
    return undefined;
  }
};

export const requestLazadaURL = async (
  marketplace: any,
  reconnect_id?: string
) => {
  const marketplaceSettingInfo = (
    await db.collection("settings").doc(marketplace).get()
  ).data();
  const companyItem = localStorage.getItem("CompanyList");
  const client = localStorage.getItem("Client");
  let companyId = "null";
  let clientId = "null";
  if (companyItem !== null) {
    companyId = JSON.parse(companyItem).value[0].id;
  }
  if (client) {
    clientId = JSON.parse(client).value.id;
  }
  const url: string =
    process.env.REACT_APP_SERVER_URL! +
    `/lazada/get_auth_url?app_key=${marketplaceSettingInfo?.app_key}&client=${clientId}&company=${companyId}&reconnect_id=${reconnect_id}`;

  try {
    const token = await bcrypt.hash(process.env.REACT_APP_SERVER_TOKEN!, 10);

    const response = await axios.post(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    // console.log("Status from Server:", response.status);
    return marketplaceSettingInfo ? response.data : "";
  } catch (error: any) {
    console.log("ERROR FROM SERVER!", error);
    return undefined;
  }
};

export const requestLazadaChatURL = async (
  marketplace: any,
  reconnect_id?: string
) => {
  const marketplaceSettingInfo = (
    await db.collection("settings").doc(marketplace).get()
  ).data();
  console.log(marketplaceSettingInfo, "marketplaceSettingInfo moop");
  const companyItem = localStorage.getItem("CompanyList");
  const client = localStorage.getItem("Client");
  let companyId = "null";
  let clientId = "null";
  if (companyItem !== null) {
    companyId = JSON.parse(companyItem).value[0].id;
  }
  if (client) {
    clientId = JSON.parse(client).value.id;
  }
  const url: string =
    process.env.REACT_APP_SERVER_URL! +
    `/lazada/get_auth_chat_url?client=${clientId}&company=${companyId}&app_key=${marketplaceSettingInfo?.app_key}&reconnect_id=${reconnect_id}`;
  console.log(url, "ini url moop");

  try {
    const token = await bcrypt.hash(process.env.REACT_APP_SERVER_TOKEN!, 10);

    const response = await axios.post(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });
    console.log(response, "ini response moop");

    console.log("Status from Server:", response.status);
    return marketplaceSettingInfo ? response.data : "";
  } catch (error: any) {
    console.log("ERROR FROM SERVER!", error);
    return undefined;
  }
};

export const requestTiktokUrl = async (marketplace: any) => {
  const marketplaceSettingInfo = (
    await db.collection("settings").doc(marketplace).get()
  ).data();
  const companyItem = localStorage.getItem("CompanyList");
  let companyId = "null";
  if (companyItem !== null) {
    companyId = JSON.parse(companyItem).value[0].id;
  }
  const url: string =
    process.env.REACT_APP_SERVER_URL! +
    `/tiktok/connect-erp?appId=${marketplaceSettingInfo?.appId}&shopRegion=${marketplaceSettingInfo?.appRegion}`;

  try {
    const token = await bcrypt.hash(process.env.REACT_APP_SERVER_TOKEN!, 10);

    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    console.log(
      `Response Get Url from ${marketplace.name}: ${response?.data?.url}`
    );
    return marketplaceSettingInfo ? response?.data : "";
  } catch (error: any) {
    console.log("ERROR FROM SERVER!", error);
    return undefined;
  }
};

export const requestShopeeURL = async (marketplace: any) => {
  const marketplaceSettingInfo = (
    await db.collection("settings").doc(marketplace).get()
  ).data();
  const companyItem = localStorage.getItem("CompanyList");
  let companyId = "null";
  if (companyItem !== null) {
    companyId = JSON.parse(companyItem).value[0].id;
  }
  const url: string =
    process.env.REACT_APP_SERVER_URL! +
    `/7days-shopee/connectAccount?partnerID=${
      marketplaceSettingInfo?.partnerId
    }&partnerKey=${marketplaceSettingInfo?.partnerKey}&callbackURL=${process.env
      .REACT_APP_SERVER_URL!}/7days-shopee/auth?companyId=${companyId}`;

  console.log(url, "___url connect shopee bypass___");

  try {
    const token = await bcrypt.hash(process.env.REACT_APP_SERVER_TOKEN!, 10);

    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    // console.log("Status from Server:", response.status);
    return marketplaceSettingInfo ? response.data : "";
  } catch (error: any) {
    console.log("ERROR FROM SERVER!", error);
    return undefined;
  }
};

export const requestShopeeChatURL = async (marketplace: any) => {
  const marketplaceSettingInfo = (
    await db.collection("settings").doc(marketplace).get()
  ).data();
  const companyItem = localStorage.getItem("CompanyList");
  let companyId = "null";
  if (companyItem !== null) {
    companyId = JSON.parse(companyItem).value[0].id;
  }
  const url: string =
    process.env.REACT_APP_SERVER_URL! +
    `/7days-shopee/connectAccountChat?partnerID=${
      marketplaceSettingInfo?.partnerId
    }&partnerKey=${marketplaceSettingInfo?.partnerKey}&callbackURL=${process.env
      .REACT_APP_SERVER_URL!}/7days-shopee/authChat?companyId=${companyId}`;

  console.log(url, "___url connect chat shopee bypass___");

  try {
    const token = await bcrypt.hash(process.env.REACT_APP_SERVER_TOKEN!, 10);

    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    // console.log("Status from Server:", response.status);
    return marketplaceSettingInfo ? response.data : "";
  } catch (error: any) {
    console.log("ERROR FROM SERVER!", error);
    return undefined;
  }
};

export const requestConnectShopeeURL = async (
  partner_id: string,
  partner_key: string,
  reconnect_id?: string
) => {
  const companyItem = localStorage.getItem("CompanyList");
  let companyId = "null";
  if (companyItem !== null) {
    companyId = JSON.parse(companyItem).value[0].id;
  }
  const url: string =
    process.env.REACT_APP_SERVER_URL! +
    `/7days-shopee/connectAccount?partnerID=${partner_id}&partnerKey=${partner_key}${
      reconnect_id && `&reconnectID=${reconnect_id}`
    }&callbackURL=${process.env
      .REACT_APP_SERVER_URL!}/7days-shopee/auth?reconnectId=${reconnect_id}&companyId=${companyId}`;

  console.log(url, "___url reconnect shopee bypass___");

  try {
    const token = await bcrypt.hash(process.env.REACT_APP_SERVER_TOKEN!, 10);

    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    // console.log("Status from Server:", response.status);
    return partner_id && partner_key ? response.data : "";
  } catch (error: any) {
    console.log("ERROR FROM SERVER!", error);
    return undefined;
  }
};

export const requestConnectShopeeChatURL = async (
  partner_id: string,
  partner_key: string,
  reconnect_id?: string
) => {
  const companyItem = localStorage.getItem("CompanyList");
  let companyId = "null";
  if (companyItem !== null) {
    companyId = JSON.parse(companyItem).value[0].id;
  }
  const url: string =
    process.env.REACT_APP_SERVER_URL! +
    `/7days-shopee/connectAccountChat?partnerID=${partner_id}&partnerKey=${partner_key}${
      reconnect_id && `&reconnectID=${reconnect_id}`
    }&callbackURL=${process.env
      .REACT_APP_SERVER_URL!}/7days-shopee/authChat?reconnectId=${reconnect_id}&companyId=${companyId}`;

  console.log(url, "___url reconnect chat shopee bypass___");

  try {
    const token = await bcrypt.hash(process.env.REACT_APP_SERVER_TOKEN!, 10);

    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    // console.log("Status from Server:", response.status);
    return partner_id && partner_key ? response.data : "";
  } catch (error: any) {
    console.log("ERROR FROM SERVER!", error);
    return undefined;
  }
};

export const requestDisconnectShopeeURL = async (
  partner_id: string,
  partner_key: string,
  app_type: string
) => {
  console.log(partner_id, "partid disconnect pkl");
  console.log(partner_key, "parkey disconnect pkl");
  const companyItem = localStorage.getItem("CompanyList");
  let companyId = "null";
  if (companyItem !== null) {
    companyId = JSON.parse(companyItem).value[0].id;
  }
  const url: string =
    process.env.REACT_APP_SERVER_URL! +
    `/7days-shopee/disconnectAccount?partnerID=${partner_id}&partnerKey=${partner_key}&callbackURL=${process
      .env
      .REACT_APP_SERVER_URL!}/7days-shopee/authdisconnect?appType=${app_type}&companyId=${companyId}`;

  console.log(url, "___url disconnect shopee bypass___");

  try {
    const token = await bcrypt.hash(process.env.REACT_APP_SERVER_TOKEN!, 10);

    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    // console.log("Status from Server:", response.status);
    return partner_id && partner_key ? response.data : "";
  } catch (error: any) {
    console.log("ERROR FROM SERVER!", error);
    return undefined;
  }
};

export const requestResendCodeShopeeURL = async (
  partner_id: string,
  partner_key: string
) => {
  console.log(partner_id, "partid disconnect pkl");
  console.log(partner_key, "parkey disconnect pkl");
  const url: string =
    process.env.REACT_APP_SERVER_URL! +
    `/7days-shopee/generateResendCodeURL?partnerID=${partner_id}&partnerKey=${partner_key}&callbackURL=${process
      .env.REACT_APP_SERVER_URL!}/7days-shopee/getResendCode`;

  try {
    const token = await bcrypt.hash(process.env.REACT_APP_SERVER_TOKEN!, 10);

    const response = await axios.get(url, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    });

    console.log("Status from Server url:", response);
    return partner_id && partner_key ? response.data : "";
  } catch (error: any) {
    console.log("ERROR FROM SERVER!", error);
    return undefined;
  }
};
