import { DocumentReference } from "@firebase/firestore-types";
import firebase from "firebase/compat/app";
import { Timestamp } from "firebase/firestore";
import Account from "./Account";

export const CountryCodeMap = {
  INDONESIA: "ID",
  MALAYSIA: "MY",
  SINGAPORE: "SG",
};

export enum OrderStatus7Days {
  // All = "ALL",
  Unpaid = "UNPAID",
  NewOrder = "NEW_ORDER",
  Processing = "PROCESSING",
  ReadyToShip = "READY_TO_SHIP",
  Shipping = "SHIPPING",
  // Delivered = "DELIVERED",
  Completed = "COMPLETED",
  Cancelled = "CANCELLED",
  Return = "RETURN",
}

export const ORDER_STATUS_LIST = Object.values(OrderStatus7Days);

// export enum OrderStatusShopee {
//   Unpaid = "UNPAID",
//   Pending = "PENDING",
//   ReadyToShip = "READY_TO_SHIP",
//   Processed = "PROCESSED",
//   RetryShip = "RETRY_SHIP",
//   Shipped = "SHIPPED",
//   ToConfirmReceive = "TO_CONFIRM_RECEIVE",
//   InCancel = "IN_CANCEL",
//   Cancelled = "CANCELLED",
//   ToReturn = "TO_RETURN",
//   Completed = "COMPLETED",
// }

// export enum OrderStatusLazada {
//   Unpaid = "UNPAID",
//   Pending = "PENDING",
//   Packed = "PACKED",
//   Repacked = "REPACKED",
//   ReadyToShipPending = "READY_TO_SHIP_PENDING",
//   ReadyToShip = "READY_TO_SHIP",
//   Shipped = "SHIPPED",
//   Delivered = "DELIVERED",
//   Returned = "RETURNED",
//   Cancelled = "CANCELLED",
//   LostBy3pl = "LOST_BY_3PL",
//   DamagedBy3pl = "DAMAGED_BY_3PL",
//   Confirmed = "CONFIRMED",
// }

// // Lazada to 7Days
// export const lazadaTo7DaysMapping = {
//   [OrderStatusLazada.Unpaid]: OrderStatus7Days.Unpaid,
//   [OrderStatusLazada.Pending]: OrderStatus7Days.Paid,
//   [OrderStatusLazada.Packed]: OrderStatus7Days.Packed,
//   [OrderStatusLazada.Repacked]: OrderStatusLazada.Repacked, //Lazada
//   [OrderStatusLazada.ReadyToShipPending]: OrderStatus7Days.Packed, //Lazada
//   [OrderStatusLazada.ReadyToShip]: OrderStatus7Days.Processed,
//   [OrderStatusLazada.Shipped]: OrderStatus7Days.Shipped,
//   [OrderStatusLazada.Delivered]: OrderStatus7Days.ToConfirmReceive,
//   [OrderStatusLazada.Returned]: OrderStatusLazada.Returned, //Lazada
//   [OrderStatusLazada.Cancelled]: OrderStatus7Days.Cancelled,
//   [OrderStatusLazada.LostBy3pl]: OrderStatusLazada.LostBy3pl, //Lazada
//   [OrderStatusLazada.DamagedBy3pl]: OrderStatusLazada.DamagedBy3pl, //Lazada
//   [OrderStatusLazada.Confirmed]: OrderStatus7Days.Completed,
// };

// // Shopee to 7Days
// export const shopeeTo7DaysMapping = {
//   [OrderStatusShopee.Unpaid]: OrderStatus7Days.Unpaid,
//   [OrderStatusShopee.Pending]: OrderStatus7Days.Unpaid,
//   [OrderStatusShopee.ReadyToShip]: OrderStatus7Days.Paid,
//   [OrderStatusShopee.Processed]: OrderStatus7Days.Processed,
//   [OrderStatusShopee.Shipped]: OrderStatus7Days.Shipped,
//   [OrderStatusShopee.ToConfirmReceive]: OrderStatus7Days.ToConfirmReceive,
//   [OrderStatusShopee.Completed]: OrderStatus7Days.Completed,
//   [OrderStatusShopee.Cancelled]: OrderStatus7Days.Cancelled,
//   [OrderStatusShopee.ToReturn]: OrderStatusShopee.ToReturn,
//   [OrderStatusShopee.InCancel]: OrderStatusShopee.InCancel,
//   [OrderStatusShopee.RetryShip]: OrderStatusShopee.RetryShip,
// };

// // Lazada to Shopee mapping
// export const lazadaToShopeeMapping = {
//   [OrderStatusLazada.Unpaid]: OrderStatusShopee.Unpaid,
//   [OrderStatusLazada.Pending]: OrderStatusShopee.ReadyToShip,
//   [OrderStatusLazada.Packed]: OrderStatusLazada.Packed, //Lazada
//   [OrderStatusLazada.Repacked]: OrderStatusLazada.Repacked, //Lazada
//   [OrderStatusLazada.ReadyToShipPending]: OrderStatusLazada.ReadyToShipPending, //Lazada
//   [OrderStatusLazada.ReadyToShip]: OrderStatusShopee.Processed,
//   [OrderStatusLazada.Shipped]: OrderStatusShopee.Shipped,
//   [OrderStatusLazada.Delivered]: OrderStatusShopee.ToConfirmReceive,
//   [OrderStatusLazada.Returned]: OrderStatusShopee.ToReturn,
//   [OrderStatusLazada.Cancelled]: OrderStatusShopee.Cancelled,
//   [OrderStatusLazada.LostBy3pl]: OrderStatusLazada.LostBy3pl, //Lazada
//   [OrderStatusLazada.DamagedBy3pl]: OrderStatusLazada.DamagedBy3pl, //Lazada
//   [OrderStatusLazada.Confirmed]: OrderStatusShopee.Completed,
// };

// // Lazada level
// export const LAZADA_STATUS_LEVEL = {
//   [OrderStatusLazada.Unpaid]: 1,
//   [OrderStatusLazada.Pending]: 2,
//   [OrderStatusLazada.Packed]: 3,
//   [OrderStatusLazada.Repacked]: 3,
//   [OrderStatusLazada.ReadyToShipPending]: 4,
//   [OrderStatusLazada.ReadyToShip]: 5,
//   [OrderStatusLazada.Shipped]: 6,
//   [OrderStatusLazada.Delivered]: 7,
//   [OrderStatusLazada.Returned]: 8,
//   [OrderStatusLazada.Cancelled]: 9,
//   [OrderStatusLazada.LostBy3pl]: 7,
//   [OrderStatusLazada.DamagedBy3pl]: 7,
//   [OrderStatusLazada.Confirmed]: 10,
// };

export interface OrderItem {
  itemID?: string;
  itemName?: string;
  itemSKU?: string;
  parentSKU?: string;
  variantID?: string;
  variantName?: string;
  variantSKU?: string;
  imageUrl?: string;
  qtyOrderItem?: number;
  originalPrice?: number;
  discountedPrice?: number;
}

export type ShippingAddress = {
  fullAddress?: string;
  detailAddress?: string;
  town?: string;
  district?: string;
  city?: string;
  state?: string;
  country?: string;
  postalCode?: string;
  countryRegion?: string;
};

export interface Order {
  id?: string;
  account?: DocumentReference;
  client?: DocumentReference;
  customer?: DocumentReference;
  marketplaceType?: string;
  ordersn?: string;
  createdAt?: Timestamp;
  marketplaceOrderCreatedAt?: Timestamp;
  updatedAt?: Timestamp;
  marketplaceOrderUpdatedAt?: Timestamp;
  isActive?: boolean;
  status?: string;
  marketplace_status?: string;
  buyerUsername?: string;
  buyerName?: string;
  receiverName?: string;
  receiverPhone?: string;
  itemList?: OrderItem[];
  messageToSeller?: string;
  shippingAddress?: ShippingAddress;
  totalAmount?: number;
  codAmount?: number;
  cod?: boolean;
  marketplaceLogisticChannelID?: string;
  marketplaceLogisticChannelName?: string;
  marketplaceLogisticChannelParent?: string;
  marketplaceDeliveryChannelName?: string;
  marketplaceDeliveryChannelParent?: string;
  shippingWarehouseIDs?: OrderShippingWarehouseID[] | "";
  shippingTrackingNumber?: string;
  shippingLabel?: string;
  shipByDate?: Timestamp;
  shippingLog?: {
    success: boolean;
    message: string;
  };
  shipTime?: Timestamp;
  priorityTag?: string;
  priorityCode?: string;
  isPriority?: boolean;
  isTap?: boolean;
  checkoutShipper?: string;
  orderPriorityNumber?: number;
  exportStatus?: string;
  isRecommendedCourier?: boolean;
  userSearchKey?: string;

  accountData?: Account;
  forwardError?: string | null;
  isForwardSuccess?: boolean;
  tokopedia_orderId?: number;
  isPrepack?: boolean;
  printedLabel?: Timestamp;
  printedLabelAt?: Timestamp;
}

export default Order;

export interface LazadaOrder extends Order {
  lazada_packageID?: string;
  lazada_orderItemIDs?: string[];
  lazada_shipping_type?: string;
  lazada_pickupProvider?: string;
  lazada_dropoffProvider?: string;
  lazada_deliveryProvider?: string;
  lazada_deliveryProviderParent?: string;
}

export type OrderPushData = {
  data: {
    items: string[];
    ordersn: string;
    status: string;
    update_time: number;
  };
  shop_id: number;
  code: number;
  timestamp: number;
};

export type OrderShippingWarehouseID = {
  index: number;
  shippingWarehouseID: string;
};

export interface LazadaOrderSchedule {
  isActive: boolean;
  start: Timestamp;
  status: string;
  type: "lazada_order_rts";
  listOrder: {
    order: DocumentReference;
    orderID?: string;
    success: boolean;
    error?: string;
  }[];
}
