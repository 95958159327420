import clsx from "clsx";
import firebase from "firebase/compat/app";
import moment from "moment";
import { FC, Fragment, PropsWithChildren, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import Account from "../../../../../../../../models/Account";
import { Overlay, OverlayTrigger, Tooltip } from "react-bootstrap";

type StatusCellProp = {
  type: string;
  credentials?: {
    [string: string]: {
      code: string;
      accessToken: string;
      refreshToken: string;
      accessTokenExpired: firebase.firestore.Timestamp;
      refreshTokenExpired: firebase.firestore.Timestamp;
      connectedAt?: firebase.firestore.Timestamp | undefined;
    };
  };
  data: any;
};

const MarketplaceAppType: FC<StatusCellProp> = ({
  type,
  credentials,
  data,
}) => {
  // const connectedAppList =
  //   type === "whatsapp"
  //     ? ["chat"]
  //     : type === "lazada" && credentials
  //     ? Object.keys(credentials).map((appType: string) => appType)
  //     : type === "tokopedia"
  //     ? ["erp", "chat"]
  //     : ["erp"];

  const [show, setShow] = useState(false);
  const target = useRef(null);
  const connectedAppList = type === "whatsapp" ? ["chat"] : ["erp", "chat"];

  let connected_erp = false;
  let connected_chat = false;
  let expired_accesstoken_erp = false;
  let expired_accesstoken_chat = false;
  const currentDate = new Date();
  if (data.type === "shopee") {
    const shopee_exp = data[`${data.type}_expiredIn`];
    const shopee_expMilliseconds =
      shopee_exp?.seconds * 1000 + shopee_exp?.nanoseconds / 1000000;
    const shopee_expDate = new Date(shopee_expMilliseconds);
    const shopee_erp_con = data?.credentials?.erp?.connectedAt;
    const shopee_chat_con = data?.credentials?.chat?.connectedAt;
    const shopee_chat_exp = data?.credentials?.chat?.expiredIn;
    const shopee_chat_expMilliseconds =
      shopee_chat_exp?.seconds * 1000 + shopee_chat_exp?.nanoseconds / 1000000;
    const shopee_chat_expDate = new Date(shopee_chat_expMilliseconds);
    const currentDate = new Date();
    if (shopee_erp_con) {
      connected_erp = true;
    }
    if (shopee_chat_con) {
      connected_chat = true;
    }
    if (shopee_expDate < currentDate) {
      expired_accesstoken_erp = true;
    }
    if (shopee_chat_expDate < currentDate) {
      expired_accesstoken_chat = true;
    }
  }
  if (data.type === "tokopedia") {
    const tokopedia_exp = data[`${data.type}_accessTokenExpired`];
    const tokopedia_expMilliseconds =
      tokopedia_exp?.seconds * 1000 + tokopedia_exp?.nanoseconds / 1000000;
    const tokopedia_expDate = new Date(tokopedia_expMilliseconds);
    connected_erp = true;
    connected_chat = true;
    if (tokopedia_expDate < currentDate) {
      expired_accesstoken_erp = true;
      expired_accesstoken_chat = true;
    }
  }
  if (data.type === "lazada") {
    const lazerp_exp = data?.credentials?.erp?.accessTokenExpired;
    const lazerp_expMilliseconds =
      lazerp_exp?.seconds * 1000 + lazerp_exp?.nanoseconds / 1000000;
    const lazerp_expDate = new Date(lazerp_expMilliseconds);
    const lazchat_exp = data?.credentials?.chat?.accessTokenExpired;
    const lazchat_expMilliseconds =
      lazchat_exp?.seconds * 1000 + lazchat_exp?.nanoseconds / 1000000;
    const lazchat_expDate = new Date(lazchat_expMilliseconds);
    if (lazchat_exp !== undefined) {
      connected_chat = true;
    }
    if (lazerp_exp !== undefined) {
      connected_erp = true;
    }
    if (lazerp_expDate < currentDate) {
      expired_accesstoken_erp = true;
    }
    if (lazchat_expDate < currentDate) {
      expired_accesstoken_chat = true;
    }
  }
  if (data.type === "tiktok") {
    const tiktok_exp = data?.credentials?.erp?.accessTokenExpired;
    const tiktok_expMilliseconds =
      tiktok_exp?.seconds * 1000 + tiktok_exp?.nanoseconds / 1000000;
    const tiktok_expDate = new Date(tiktok_expMilliseconds);
    const tiktok_erp_con = data?.credentials?.erp?.connectedAt;
    const tiktok_chat_con = data?.credentials?.chat?.connectedAt;
    const tiktok_chat_exp = data?.credentials?.chat?.accessTokenExpired;
    const tiktok_chat_expMilliseconds =
      tiktok_chat_exp?.seconds * 1000 + tiktok_chat_exp?.nanoseconds / 1000000;
    const tiktok_chat_expDate = new Date(tiktok_chat_expMilliseconds);
    const currentDate = new Date();
    if (tiktok_erp_con) {
      connected_erp = true;
    }
    if (tiktok_chat_con) {
      connected_chat = true;
    }
    if (tiktok_expDate < currentDate) {
      expired_accesstoken_erp = true;
    }
    if (tiktok_chat_expDate < currentDate) {
      expired_accesstoken_chat = true;
    }
  }
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 900px)" });
  const { t } = useTranslation();
  console.log(data.type, data.name, expired_accesstoken_erp, "expacctoken");
  console.log(
    data.name,
    connected_chat,
    connected_erp,
    data,
    "connect sdattss"
  );
  return (
    <div
      style={{ width: isTabletOrMobile ? "50px" : "100px" }}
      className={clsx(
        "p-2 w-100 rounded-3 text-center fw-bold d-flex justify-content-center mx-auto"
      )}
    >
      {connectedAppList.sort().map((appType: any, index: any) => {
        let tooltipText = "";
        if (
          (appType === "erp" && connected_erp && !expired_accesstoken_erp) ||
          (appType === "chat" && connected_chat && !expired_accesstoken_chat)
        ) {
          tooltipText = t("Marketplace.Status.Connected");
        } else if (
          (appType === "erp" && connected_erp && expired_accesstoken_erp) ||
          (appType === "chat" && connected_chat && expired_accesstoken_chat)
        ) {
          tooltipText = t("Marketplace.Status.Expired");
        } else {
          tooltipText = t("Marketplace.Status.Disconnected");
        }

        return (
          <OverlayTrigger
            key={appType}
            placement="top"
            overlay={
              <Tooltip
                id={`tooltip-${appType}`}
                style={{ backgroundColor: "#f0f8ff", color: "black" }}
              >
                {tooltipText}
              </Tooltip>
            }
          >
            <div
              className={`btn badge mx-1 text-capitalize cursor-pointer`}
              style={{
                backgroundColor: `${
                  (appType === "erp" &&
                    connected_erp === true &&
                    expired_accesstoken_erp === false) ||
                  (appType === "chat" &&
                    connected_chat === true &&
                    expired_accesstoken_chat === false)
                    ? "#E8FFF3" //Connected
                    : (appType === "erp" &&
                        connected_erp === true &&
                        expired_accesstoken_erp === true) ||
                      (appType === "chat" &&
                        connected_chat === true &&
                        expired_accesstoken_chat === true)
                    ? "#FFE8E8" //Expired Access Token
                    : "#E4E6EF" //Disconnected
                }`,
                color: `${
                  (appType === "erp" &&
                    connected_erp === true &&
                    expired_accesstoken_erp === false) ||
                  (appType === "chat" &&
                    connected_chat === true &&
                    expired_accesstoken_chat === false)
                    ? "#50CD89"
                    : (appType === "erp" &&
                        connected_erp === true &&
                        expired_accesstoken_erp === true) ||
                      (appType === "chat" &&
                        connected_chat === true &&
                        expired_accesstoken_chat === true)
                    ? "#CD5050"
                    : "grey"
                }`,
                opacity: 1,
              }}
              onMouseOver={() => setShow(!show)}
            >
              <span>{appType.toUpperCase()}</span>
            </div>
          </OverlayTrigger>
        );
      })}
    </div>
  );
};

export default MarketplaceAppType;
